import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SMBBusiness } from 'src/types/organization';

type SetSMBListAction = PayloadAction<SMBBusiness[]>;
interface SMBState {
  smbs: SMBBusiness[];
}

const initialState: SMBState = {
  smbs: [],
};

const reducers = {
  setSMBList(state: SMBState, action: SetSMBListAction): void {
    state.smbs = action.payload;
  },
};

export const slice = createSlice({
  name: 'smb',
  initialState,
  reducers,
});

export const { reducer } = slice;
