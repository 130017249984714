import { combineReducers } from '@reduxjs/toolkit';

import { reducer as exampleReducer } from 'src/slices/example';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as smbReducer } from 'src/slices/smb';
import { reducer as organizationChatReducer } from 'src/slices/organization-chat';
import { reducer as dockReducer } from 'src/slices/dock';
import { reducer as smbChatReducer } from 'src/slices/smb-chat';
import { reducer as aiPromptReducer } from 'src/slices/ai-prompt';
import { reducer as bannerReducer } from 'src/slices/banner';
import { reducer as featureFlagsReducer } from 'src/slices/feature-flags';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as enterpriseEventPlannerReducer } from 'src/slices/enterprise-event-planner';
import { reducer as supplierEventPlannerReducer } from 'src/slices/supplier-event-planner';
import { reducer as v2MemberChatReducer } from 'src/slices/v2-member-chat';
import { reducer as onboardingReducer } from 'src/slices/onboarding';
import { reducer as notificationsReducer } from 'src/slices/notifications';

export const rootReducer = combineReducers({
  example: exampleReducer,
  chat: chatReducer,
  smb: smbReducer,
  organizationChat: organizationChatReducer,
  smbChat: smbChatReducer,
  dock: dockReducer,
  aiPrompt: aiPromptReducer,
  banner: bannerReducer,
  featureFlags: featureFlagsReducer,
  calendar: calendarReducer,
  enterpriseEventPlanner: enterpriseEventPlannerReducer,
  supplierEventPlanner: supplierEventPlannerReducer,
  v2MemberChat: v2MemberChatReducer,
  onboarding: onboardingReducer,
  notifications: notificationsReducer,
});
