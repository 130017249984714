import AuthAxios from '../auth-axios';
import { VITE_API_LEVELPLANE } from 'src/config';
import { UploadSMB } from 'src/types/upload-smbs';
import { NovaVerbosityLevel } from 'src/types/user';

class UploadSMBAPI extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async checkInteractionStyle(): Promise<NovaVerbosityLevel | null> {
    try {
      const response = await this.axiosInstance.get(`/self`);
      if (response?.data?.nova_settings?.verbosity_level) {
        return response.data.nova_settings.verbosity_level;
      }
      return null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async hasMembers(orgId: string) {
    try {
      const { data } = await this.axiosInstance.get(`/organizations/${orgId}/smbs/`);
      if (data.count > 0) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async selectCompanionMode(mode: NovaVerbosityLevel | null): Promise<unknown> {
    try {
      const { data } = await this.axiosInstance.patch(`/self`, {
        nova_settings: {
          verbosity_level: mode
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async uploadMembersJSON(smbs: UploadSMB[]): Promise<unknown> {
    try {
      const { data } = await this.axiosInstance.post(`/smbs/massive-upload`, smbs, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async uploadMembersFile(smbFile: File): Promise<unknown> {
    try {
      const formData = new FormData();
      formData.append('file', smbFile);
      const { data } = await this.axiosInstance.post(`/smbs/massive-upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async downloadTemplate(): Promise<Blob> {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/download-template`, {
        params: {
          template_type: 'upload_template',
        },
        responseType: 'blob',
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export const uploadSMBAPI = new UploadSMBAPI();
