import { notificationsAPI } from 'src/api/notifications';
import { slice } from 'src/slices/notifications';
import { AppThunk } from 'src/store';

const dismissNotification =
  (notificationId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await notificationsAPI.changeStatus(notificationId, 'dismissed');
    dispatch(slice.actions.removeNotification(notificationId));
  };

export const thunks = { dismissNotification };
