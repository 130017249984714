import { tokens } from '../tokens';

export const es = {
  [tokens.prefix.prefixCompany]: 'negocio',
  [tokens.suffix.suffixCompanyPlural]: 's',
  [tokens.suffix.suffixCompanySingular]: '',

  [tokens.common.languageChanged]: 'Se ha cambiado el idioma',
  [tokens.common.termsConditions]: 'Términos y Condiciones',
  [tokens.common.close]: 'Cerrar',
  [tokens.common.save]: 'Guardar',
  [tokens.common.companyName]: 'Nombre de la Empresa',
  [tokens.common.address]: 'Dirección',
  [tokens.common.website]: 'Sitio web',
  [tokens.common.phoneNumber]: 'Número de Teléfono',
  [tokens.common.email]: 'Correo Electrónico',
  [tokens.common.keywords]: 'Palabras Clave',
  [tokens.common.companyStatement]: 'Declaración de la Empresa',
  [tokens.common.edit]: 'Editar',
  [tokens.common.name]: 'Nombre',
  [tokens.common.actions]: 'Acciones',
  [tokens.common.delete]: 'Eliminar',
  [tokens.common.search]: 'Buscar',
  [tokens.common.details]: 'Detalles',
  [tokens.common.sector]: 'Sector',
  [tokens.common.sendInterviews]: 'Enviar Entrevistas',
  [tokens.common.stateCountry]: 'Estado/País',
  [tokens.common.typeHere]: 'Escriba aquí',
  [tokens.common.numberOfBusinesses]: 'Número de Negocios',
  [tokens.common.uploading]: 'Subiendo',
  [tokens.common.cancel]: 'Cancelar',
  [tokens.common.submit]: 'Enviar',
  [tokens.common.submitting]: 'Enviando',

  [tokens.banner.enrichmentTitle]: 'Estatus de Enriquecimiento',
  [tokens.banner.enrichmentBody]: '{{percentage}}% del enriquecimiento ha sido completado',
  [tokens.banner.enrichmentError]:
    'Ocurrio un problema al intentar obtener el porcetaje de enriquecimiento. Porfavor, actualiza la pagina para intentar de nuevo',
  [tokens.banner.enrichmentLoading]: 'Obteniendo información...',
  [tokens.banner.enrichmentUpdatedAt]: 'Última información obtenida a la hora: {{updatedAt}}',

  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.error]: 'Error',
  [tokens.nav.login]: 'Acceso',
  [tokens.nav.register]: 'Registrarse',

  [tokens.tabs.members]: 'Miembros',
  [tokens.tabs.events]: 'Eventos',

  [tokens.dashboard.tiles.totalManufacturers]: 'Total de Manufacturas',
  [tokens.dashboard.tiles.totalPaidUsers]: 'Usuarios Pagados',
  [tokens.dashboard.tiles.totalMeetingsArranged]: 'Reuniones Agendadas',
  [tokens.dashboard.tiles.commoditiesNeeded]: 'Productos Solicitados',

  [tokens.dashboard.tiles.totalMembers]: 'Total Miembros',
  [tokens.dashboard.tiles.totalThreshold]: 'Proveedores por encima del umbral de riesgo',
  [tokens.dashboard.tiles.membersEnriched]: 'Miembros Enriquecidos',
  [tokens.dashboard.tiles.overallSupplyChain]:
    'Riesgo global de la cadena de suministro Variación interanual',
  [tokens.dashboard.tiles.sectorDistribution]: 'Distribución por sector',
  [tokens.dashboard.tiles.topSectors]: 'Sectores Destacados',
  [tokens.dashboard.tiles.averageCompanySize]: 'Tamaño Promedio de Negocios',
  [tokens.dashboard.tiles.demoDistribution]: 'Proveedores afectados en Oriente Medio',
  [tokens.dashboard.tiles.onlinePresence]: 'Presencia en Línea Reclamada',
  [tokens.dashboard.tiles.totalMembersBackTile]: 'Tienes un total de **{{totalMembers}}** miembros',
  [tokens.dashboard.tiles.successfullyEnriched]:
    'Tienes **{{enriched}}** negocios enriquecidos satisfactoriamente',
  [tokens.dashboard.tiles.noSuccessfullyEnriched]: 'No tienes negocios enriquecidos',
  [tokens.dashboard.tiles.failedEnriched]:
    '**{{failed}}** negocio{{plural}} donde el enriquecimiento falló',
  [tokens.dashboard.tiles.awaitingEnrichment]:
    '**{{awaitingEnrichment}}** negocio{{plural}} esperando a ser enriquecidos',
  [tokens.dashboard.tiles.progressEnrichment]:
    '**{{inProgress}}** negocio{{plural}} estan siendo enriquecidos en este momento',
  [tokens.dashboard.tiles.enrichmentNotPossible]:
    '**{{enrichmentNotPossible}}** negocio{{plural}} donde el enriquecimiento no es posible',
  [tokens.dashboard.tiles.noCurrentEnrichment]:
    'Y **no hay negocios** actualmente siendo en el proceso de enriquecimiento',
  [tokens.dashboard.tiles.sectorDistributionTileBackHeading]:
    'A continuación se muestra un desglose de los múltiples sectores de los que forman parte sus negocios',
  [tokens.dashboard.tiles.averageCompanyTileBackHeading]:
    'Esta es una lista de todos los tamaños de categorías a las que pertenecen sus empresas:',
  [tokens.dashboard.tiles.presenceNotClaimed]: 'No hay registros de negocios para mostrar.',
  [tokens.dashboard.tiles.presenceAllClaimed]:
    'Todos los **{{claimedCount}}** negocios han reclamado ya su sitio en línea.',
  [tokens.dashboard.tiles.presenceClaimedCount0]:
    'Hay **{{notClaimedCount}}** negocios que **no han** reclamado su presencia en línea, y **ningún** negocio a reclamado su presencia en línea.',
  [tokens.dashboard.tiles.presenceClaimedCount]:
    'Hay **{{notClaimedCount}}** negocios que no han reclamado su presencia en línea y **{{claimedCount}}** negocios que ya han reclamado su sitio.',

  [tokens.welcome.title]: '¡Bienvenido a LevelPlane!',
  [tokens.welcome.subtitle]:
    'Estás a punto de formar parte de un servicio de inteligencia artificial de vanguardia diseñado para satisfacer todas tus necesidades empresariales.',
  [tokens.welcome.prompt1]:
    'Desde conectarte con pequeñas empresas de todo el mundo hasta ayudar a facilitar acuerdos con ellas, LevelPlane sirve como tu solución integral para todas tus necesidades empresariales. Nuestro avanzado modelo de inteligencia artificial, impulsado por tecnología de vanguardia, va más allá de las redes convencionales. Te conecta de manera inteligente con los socios comerciales más adecuados en todas las regiones, ofreciendo oportunidades inigualables para el crecimiento y la colaboración. Un chatbot personalizado que construimos exclusivamente para ti no solo establecerá conexiones sino que también actuará como un representante virtual, comunicándose de manera competente en tu nombre.',
  [tokens.welcome.prompt2]: 'Comience por realizar la entrevista con nuestro Asistente de IA. ',
  [tokens.welcome.changes]:
    'Realiza los cambios necesarios en tu información para completar la creación de tu cuenta.',
  [tokens.welcome.keywordBlur]: 'Agregue 10 palabras para describir su negocio.',
  [tokens.welcome.passwordPrompt]: 'Hagámoslo más fácil para la próxima vez.',
  [tokens.welcome.passwordMessage]: 'Presiona guardar para empezar.',
  [tokens.welcome.interview.pressBarSpace]:
    'Presiona la barra espaciadora para hablar y suéltala cuando termines de hablar.',
  [tokens.welcome.interview.pressButton]:
    'Mantenga presionado el botón mientras habla y suéltelo después de terminar.',
  [tokens.welcome.interview.startInterview]: 'Comenzar la entrevista',
  [tokens.welcome.interview.resumeInterview]: 'Continuar la entrevista',

  [tokens.chat.openingMessage]:
    'Estoy aquí para ayudarte a encontrar proveedores y prestadores de servicios en el centro de México, incluyendo los estados de Aguascalientes, Guanajuato, Jalisco, Querétaro y San Luis Potosi. ¡Encontremos la opción perfecta para tus necesidades de manera rápida y sencilla! Solo dime qué estás buscando...',
  [tokens.chat
    .openingMessageDemo3]: `Estoy aquí para ayudarte a establecer y expandir tu negocio en Dubái y encontrar la pareja perfecta para tus necesidades. Solo dime qué estás buscando...`,
  [tokens.chat.hi]: '¡Hola!',
  [tokens.chat.leaveAMessage]: 'Escribe un mensaje',
  [tokens.chat.conversationStarterPrimary1]: 'Ayúdame a encontrar',
  [tokens.chat.conversationStarterSecondary1]: 'un proveedor de **hormigón para construcción**.',
  [tokens.chat.conversationStarterPrimary2]: 'Muéstrame',
  [tokens.chat.conversationStarterSecondary2]:
    'una fuente confiable de **materiales de vidrio de alta calidad**.',
  [tokens.chat.conversationStarterPrimary3]: 'Busca',
  [tokens.chat.conversationStarterSecondary3]: 'un vendedor de **equipos y suministros médicos**.',
  [tokens.chat.conversationStarterPrimary4]: 'Busca',
  [tokens.chat.conversationStarterSecondary4]:
    'un proveedor de renombre de **fertilizantes agrícolas**.',

  [tokens.chat.chatLoaderMessage1]: 'Analizando tu petición',
  [tokens.chat.chatLoaderMessage2]: 'Aún sigo recabando información para ti',
  [tokens.chat.chatLoaderMessage3]: 'Trabajando para encontrar la mejor respuesta',
  [tokens.chat.chatLoaderMessage4]: '¡Casi termino! Finalizando detalles',

  [tokens.chambers.memberList]: 'Lista de Miembros',

  [tokens.errors.dashboardAPI]: 'Hubo un problema al obtener la información del dashboard',

  [tokens.feedback.sendFeedback]: 'Enviar Feedback',
  [tokens.feedback.title]: 'Por favor, escriba su feedback enseguida',
  [tokens.feedback.placeholder]: 'Escribe tu feedback aquí',
  [tokens.feedback.submitSuccess]: '¡Gracias por tu feedback!',
  [tokens.feedback.submitFailure]:
    'Ocurrió un problema al enviar tu feedback. Por favor, inténtalo de nuevo',

  [tokens.companySizeMap.a]: 'Auto-empleado',
  [tokens.companySizeMap.b]: '1 - 10 empleados',
  [tokens.companySizeMap.c]: '11 - 50 empleados',
  [tokens.companySizeMap.d]: '51 - 200 empleados',
  [tokens.companySizeMap.e]: '201 - 500 empleados',
  [tokens.companySizeMap.f]: '501 - 1000 empleados',
  [tokens.companySizeMap.g]: '1001 - 5000 empleados',
  [tokens.companySizeMap.h]: '5001 - 10,000 empleados',
  [tokens.companySizeMap.i]: '+10,000 empleados',
};
