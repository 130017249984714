import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type EnetrpriseDemoAction = PayloadAction<boolean>;

interface State {
  isEnterpriseDemo: boolean;
}

const initialState: State = {
  isEnterpriseDemo: false,
};

const reducers = {
  setEnterpriseDemo(state: State, action: EnetrpriseDemoAction): void {
    state.isEnterpriseDemo = action.payload;
  },
};

export const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers,
});

export const { reducer } = slice;
