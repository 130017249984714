import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type DockOrientation = 'left' | 'right' | 'minimized';

type SetDockOrientationAction = PayloadAction<DockOrientation>;

type SetShowDockAction = PayloadAction<boolean>;

interface SomeState {
  dockOrientation: DockOrientation;
  showDock: boolean;
}

const initialState: SomeState = {
  dockOrientation: 'minimized',
  showDock: true,
};

const reducers = {
  setDockOrientation(state: SomeState, action: SetDockOrientationAction): void {
    state.dockOrientation = action.payload;
  },
  setShowDock(state: SomeState, action: SetShowDockAction): void {
    state.showDock = action.payload;
  },
};

export const slice = createSlice({
  name: 'dock',
  initialState,
  reducers,
});

export const { reducer } = slice;
