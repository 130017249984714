import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CHAT_AI_AUTHOR_KEY, LOADER_ICON } from 'src/api/chat/data';
import { Message, ChatThread } from 'src/types/chat';
import { removeFromArray } from 'src/utils/array';
import { objFromArray } from 'src/utils/obj-from-array';

type GetThreadsAction = PayloadAction<ChatThread[]>;

type GetThreadAction = PayloadAction<ChatThread | null>;

type LoadThreadMessagesAction = PayloadAction<{ threadId: string; messages: Message[] }>;

type MarkThreadAsSeenAction = PayloadAction<string>;

type SetCurrentThreadAction = PayloadAction<string | undefined>;

type AddMessageAction = PayloadAction<{ message: Message; threadId: string }>;

type SetChatTab = PayloadAction<{ chatTab: ChatTab }>;

export const AI_CHAT_TAB = 0;
export const AI_INTERVIEW_TAB = 1;
export const INSIGHTS_TAB = 2;
export type ChatTab = typeof AI_CHAT_TAB | typeof AI_INTERVIEW_TAB | typeof INSIGHTS_TAB;

interface SMBChatState {
  currentThreadId?: string;
  threads: {
    byId: Record<string, ChatThread>;
    allIds: string[];
  };
  currentChatTab: ChatTab;
}

const initialState: SMBChatState = {
  currentThreadId: undefined,
  threads: {
    allIds: [],
    byId: {},
  },
  currentChatTab: AI_CHAT_TAB,
};

const reducers = {
  getThreads(state: SMBChatState, action: GetThreadsAction): void {
    const threads = action.payload;

    state.threads.byId = objFromArray(threads);
    state.threads.allIds = Object.keys(state.threads.byId);
  },
  getThread(state: SMBChatState, action: GetThreadAction): void {
    const thread = action.payload;

    if (thread) {
      state.threads.byId[thread.id!] = thread;

      if (!state.threads.allIds.includes(thread.id!)) {
        state.threads.allIds.unshift(thread.id!);
      }
    }
  },
  loadThreadMessages(state: SMBChatState, action: LoadThreadMessagesAction) {
    state.threads.byId[action.payload.threadId] = {
      ...state.threads.byId[action.payload.threadId],
      messages: action.payload.messages,
    };
  },
  markThreadAsSeen(state: SMBChatState, action: MarkThreadAsSeenAction): void {
    const threadId = action.payload;
    const thread = state.threads.byId[threadId];

    if (thread) {
      thread.unreadCount = 0;
    }
  },
  setCurrentThread(state: SMBChatState, action: SetCurrentThreadAction): void {
    state.currentThreadId = action.payload;
  },
  addMessage(state: SMBChatState, action: AddMessageAction): void {
    const { threadId, message } = action.payload;
    const thread = state.threads.byId[threadId];
    const lastIndex = thread.messages.length - 1;

    // Remove loader message if any
    if (thread.messages[lastIndex]?.id === LOADER_ICON) {
      thread.messages = removeFromArray(lastIndex, thread.messages);
    }

    if (thread) {
      if (
        message.eventType === 'chunk_stream_response' ||
        message.eventType === 'end_stream_response'
      ) {
        thread.messages[lastIndex].body += message.body;
      } else {
        thread.messages.push(message);
      }
    }

    // Add loader message
    if (message.authorId !== CHAT_AI_AUTHOR_KEY) {
      thread.messages.push({
        attachments: [],
        authorId: CHAT_AI_AUTHOR_KEY,
        body: 'Gathering info...',
        contentType: 'loading',
        createdAt: new Date().getTime(),
        id: LOADER_ICON,
        read: false,
      });
    }
  },
  setCurrentChatTab(state: SMBChatState, action: SetChatTab): void {
    state.currentChatTab = action.payload.chatTab;
  },
};

export const slice = createSlice({
  name: 'smbChat',
  initialState,
  reducers,
});

export const { reducer } = slice;
