import React, { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from 'src/hooks/use-auth';
import { paths } from 'src/paths';

interface ChamberAuthGuardProps {
  children: ReactNode;
}

export const AuthChamberGuard: FC<ChamberAuthGuardProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const { user, enableTabs } = useAuth();
  const { org_id } = user || {};

  useEffect(() => {
    if (!org_id) {
      navigate(paths.serverError);
    }
    enableTabs(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org_id, navigate]);

  return <>{children}</>;
};

export const AuthSmbGuard: FC<ChamberAuthGuardProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const { user, enableTabs } = useAuth();
  const { smb_id } = user || {};
  useEffect(() => {
    if (!smb_id) {
      navigate(paths.serverError);
    }
    enableTabs(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smb_id, navigate]);

  return <>{children}</>;
};
