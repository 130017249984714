import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type SetBannerVisibilityAction = PayloadAction<boolean>;

interface State {
  isBannerVisible: boolean;
}

const initialState: State = {
  isBannerVisible: false,
};

const reducers = {
  setBannerVisibility(state: State, action: SetBannerVisibilityAction): void {
    state.isBannerVisible = action.payload;
  },
};

export const slice = createSlice({
  name: 'banner',
  initialState,
  reducers,
});

export const { reducer } = slice;
