export function removeFromArray<T>(index: number, array: T[]): T[] {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function addToArray<T>(element: T, array: T[]): T[] {
  return [...array, element];
}

export function addToBeginOfArray<T>(element: T, array: T[]): T[] {
  return [element, ...array];
}

export function editElement<T>(index: number, newElement: T, array: T[]): T[] {
  if (index < 0 || index >= array.length) {
    throw new Error('Index out of bounds');
  }
  return [...array.slice(0, index), newElement, ...array.slice(index + 1)];
}
