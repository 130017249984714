import { QueryFunctionContext, useQuery } from 'react-query';
import { smbApi } from 'src/api/smb';
import { useAuth } from './use-auth';
import { useState } from 'react';
import { Notification, NotificationEnrichmentExtra } from 'src/types/notifications';
import { ENRICHMENT_NOTIFICATION_ID } from 'src/config';
import { createResourceId } from 'src/utils/create-resource-id';

const useEnrichmentProgressNotification = (): {
  enrichmentNotification: Notification | null;
  isRefetching: boolean;
} => {
  const { user } = useAuth();
  const [notification, setNotification] = useState<Notification | null>(null);

  const fetchPercentage = (query: QueryFunctionContext<string[], unknown>) => {
    const { queryKey } = query;
    const [, orgId] = queryKey;
    return smbApi.getEnrichmentPercentage(orgId);
  };

  const { isRefetching, dataUpdatedAt } = useQuery({
    queryKey: ['getEnrichmentPercentage', user?.org_id as string],
    enabled: !!user?.org_id,
    queryFn: fetchPercentage,
    onSuccess: (data) => {
      setNotification({
        audience: 'org',
        chat_id: createResourceId(),
        extra: {
          progress: data.percentage_completed,
        } as NotificationEnrichmentExtra,
        notification_id: ENRICHMENT_NOTIFICATION_ID,
        notification_type: 'enrichment_progress',
        org_id: user?.org_id as string,
        status: 'new',
        text: `The enrichment is ${data.percentage_completed}% completed.`,
        timestamp: dataUpdatedAt === 0 ? Date.now() : dataUpdatedAt,
        title: 'Enrichment Progress',
        user_id: user?.id as string,
      });
    },
  });

  return { enrichmentNotification: notification, isRefetching };
};

export default useEnrichmentProgressNotification;
