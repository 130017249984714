import { tokens } from '../tokens';

export const de = {
  [tokens.common.languageChanged]: 'Sprache geändert',
  [tokens.common.termsConditions]: 'Allgemeine Geschäftsbedingungen',
  [tokens.common.close]: 'Schließen',
  [tokens.common.save]: 'Speichern',
  [tokens.common.companyName]: 'Firmenname',
  [tokens.common.address]: 'Adresse',
  [tokens.common.website]: 'Webseite',
  [tokens.common.phoneNumber]: 'Telefonnummer',
  [tokens.common.email]: 'E-Mail',
  [tokens.common.keywords]: 'Stichwörter',
  [tokens.common.companyStatement]: 'Firmenaussage',
  [tokens.common.edit]: 'Bearbeiten',
  [tokens.common.name]: 'Name',
  [tokens.common.actions]: 'Aktionen',
  [tokens.common.delete]: 'Löschen',
  [tokens.common.search]: 'Suche',
  [tokens.common.details]: 'Details',
  [tokens.common.sendInterviews]: 'Send Interviews',
  [tokens.common.stateCountry]: 'Staat/Land',

  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.error]: 'Fehler',
  [tokens.nav.login]: 'Anmeldung',
  [tokens.nav.register]: 'Registrieren',

  [tokens.welcome.title]: 'Willkommen bei LevelPlane!',
  [tokens.welcome.subtitle]:
    'Sie stehen kurz davor, Teil eines innovativen KI-Dienstes zu werden, der sich um all Ihre geschäftlichen Bedürfnisse kümmert.',
  [tokens.welcome.prompt1]:
    'Vom Verbinden mit kleinen Unternehmen weltweit bis hin zur Unterstützung bei der Abwicklung von Geschäften mit ihnen ist LevelPlane Ihre umfassende Lösung für alle Ihre geschäftlichen Bedürfnisse. Unser fortschrittliches KI-Modell, angetrieben von modernster Technologie, geht über herkömmliche Netzwerke hinaus. Es verbindet Sie intelligent mit den am besten geeigneten Geschäftspartnern in allen Regionen und bietet beispiellose Chancen für Wachstum und Zusammenarbeit. Ein maßgeschneiderter Chatbot, den wir exklusiv für Sie entwickelt haben, wird nicht nur Verbindungen herstellen, sondern auch als virtueller Vertreter fungieren und sich kompetent in Ihrem Namen verständigen.',
  [tokens.welcome.prompt2]: 'Beginnen Sie mit dem Interview mit unserem KI-Assistenten.',
  [tokens.welcome.changes]:
    'Nimm bitte alle erforderlichen Änderungen an deinen Informationen vor, um die Erstellung deines Kontos abzuschließen.',
  [tokens.welcome.keywordBlur]:
    'Fügen Sie bitte 10 Wörter hinzu, um Ihr Unternehmen zu beschreiben.',
  [tokens.welcome.passwordPrompt]: 'Lass uns das für das nächste Mal einfacher machen.',
  [tokens.welcome.passwordMessage]: 'Klicke auf Speichern, um zu beginnen.',
  [tokens.welcome.interview.pressBarSpace]:
    'Drücken Sie zum Sprechen die Leertaste und lassen Sie sie los, wenn Sie mit dem Sprechen fertig sind.',
  [tokens.welcome.interview.pressButton]:
    'Halten Sie die Taste gedrückt, während Sie sprechen, und lassen Sie sie los, wenn Sie fertig sind.',
  [tokens.welcome.interview.startInterview]: 'Starten Sie das Interview',
  [tokens.welcome.interview.resumeInterview]: 'Resume the interview',

  [tokens.chat.openingMessage]:
    'Ich bin hier, um Ihnen zu helfen, Lieferanten und Dienstleister in Zentralmexiko, einschließlich der Bundesstaaten Aguascalientes, Guanajuato, Jalisco, Querétaro und San Luis Potosi, zu finden. Lassen Sie uns schnell und einfach die perfekte Lösung für Ihre Bedürfnisse finden! Sagen Sie mir einfach, wonach Sie suchen...',
  [tokens.chat
    .openingMessageDemo3]: `Ich bin hier, um Ihnen zu helfen, Ihr Geschäft in Dubai zu gründen und zu erweitern und die perfekte Übereinstimmung für Ihre Bedürfnisse zu finden. Sagen Sie mir einfach, wonach Sie suchen...`,
  [tokens.chat.hi]: 'Hallo!',
  [tokens.chat.leaveAMessage]: 'Schreibe eine Nachricht',
  [tokens.chat.conversationStarterPrimary1]: 'Hilf mir zu finden',
  [tokens.chat.conversationStarterSecondary1]: 'einen Anbieter für **Bau-Beton**.',
  [tokens.chat.conversationStarterPrimary2]: 'Zeige mir',
  [tokens.chat.conversationStarterSecondary2]:
    'eine zuverlässige Quelle für **hochwertige Glasmaterialien**.',
  [tokens.chat.conversationStarterPrimary3]: 'Suche nach',
  [tokens.chat.conversationStarterSecondary3]:
    'einem Anbieter von **medizinischen Geräten und Zubehör**.',
  [tokens.chat.conversationStarterPrimary4]: 'Suche nach',
  [tokens.chat.conversationStarterSecondary4]:
    'einem seriösen Anbieter von **landwirtschaftlichen Düngemitteln**.',

  [tokens.chat.chatLoaderMessage1]: 'Ihre Anfrage wird analysiert',
  [tokens.chat.chatLoaderMessage2]: 'Ich sammle immer noch Informationen für Sie',
  [tokens.chat.chatLoaderMessage3]: 'Ich arbeite daran, die beste Antwort zu finden',
  [tokens.chat.chatLoaderMessage4]: 'Gleich geschafft! Einzelheiten werden finalisiert',

  [tokens.chambers.memberList]: 'Mitgliederliste',

  [tokens.errors.dashboardAPI]:
    'Beim Versuch, die Daten für das Dashboard abzurufen, ist ein Fehler aufgetreten',
};
