import { Box, CircularProgress } from '@mui/material';
import { Logo } from './logo';
import { FC } from 'react';

interface LoadingPageProps {
  theme?: 'light' | 'dark';
}

const LoadingPage: FC<LoadingPageProps> = (props) => {
  const { theme = 'dark' } = props;
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: (themeT) =>
          theme === 'dark' ? themeT.palette.primary.dark : themeT.palette.background.paper,
      }}
    >
      <CircularProgress
        size={60}
        color="secondary"
        sx={{ mb: 2 }}
      />
      {theme === 'dark' && <Logo />}
    </Box>
  );
};

export default LoadingPage;
