import { styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { ReactNode } from 'react';

export const leftBarDrawer = 70;

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})<{
  open?: boolean;
  drawerWidth?: number;
}>(({ theme, open, drawerWidth }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  height: 'calc(100vh - 60px)',
  maxHeight: 'calc(100vh - 60px)',
  overflowY: 'hidden',
  marginTop: '60px',
  backgroundColor: 'white',
  flex: 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`, // Subtract the width of Drawer and Sidebar (adjust 80px to your Box's width)
  marginLeft: `-${drawerWidth}px + ${leftBarDrawer}`,
  overflowX: 'auto',
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerWidth}px - 80px)`, // Adjust when the Drawer is open
    marginLeft: 0,
  }),
}));

interface AppBarProps {
  open?: boolean;
  drawerWidth?: number;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${leftBarDrawer}px)`,
  marginLeft: `${leftBarDrawer}px`,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = (props: { children: ReactNode; open: boolean; drawerWidth: number }) => (
  <MuiDrawer
    sx={{
      width: props.open ? props.drawerWidth + leftBarDrawer : '70px !important', // Collapse but leave Box width
      maxWidth: props.open ? props.drawerWidth + leftBarDrawer : '70px !important',
      flexShrink: 0,
      transition: 'width 0.3s ease-out', // Smooth transition
      '& .MuiDrawer-paper': {
        display: 'flex',
        flexDirection: 'row',
        left: 'unset',
        width: props.open ? props.drawerWidth + leftBarDrawer : '70px !important',
        transition: 'width 0.3s ease-out',
        boxSizing: 'border-box',
        bgcolor: '#f7f7f7',
        overflowX: 'hidden',
        ...(!props.open && {
          transform: 'unset !important',
          visibility: 'visible !important',
        }),
      },
    }}
    variant="persistent"
    anchor="left"
    open={props.open}
  >
    {props.children}
  </MuiDrawer>
);
