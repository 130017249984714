import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { Layout as MembersLayout } from 'src/layouts/v2-members';
import { AuthGuard } from 'src/guards/auth-guard';

const Index = lazy(() => import('src/pages/v2-members/index'));
const ChatPage = lazy(() => import('src/pages/v2-members/chat'));
const BusinessProfile = lazy(() => import('src/pages/v2-members/business-profile'));
const SettingsPage = lazy(() => import('src/pages/v2-members/settings'));
const NotificationsPage = lazy(() => import('src/pages/v2-members/notifications'));
const RequestsPage = lazy(() => import('src/pages/v2-members/skills/requests'));
const RequestDetailsPage = lazy(
  () => import('src/pages/v2-members/skills/requests/request-details')
);
const DiscoverPage = lazy(() => import('src/pages/_exp/discover/discover'));
const InsightsPage = lazy(() => import('src/pages/v2-members/insights'));

export const V2MembersRoutes: RouteObject[] = [
  {
    path: 'business',
    element: (
      <AuthGuard>
        <MembersLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </MembersLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: 'chat',
        element: <ChatPage />,
      },
      {
        path: 'profile',
        element: <BusinessProfile />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'notifications',
        element: <NotificationsPage />,
      },
      {
        path: 'discover',
        element: <DiscoverPage />,
      },
      {
        path: 'insights',
        element: <InsightsPage />,
      },
      // {
      //   path: 'canvas',
      //   element: <CanvasPage />,
      // },
      {
        path: 'skills',
        children: [
          {
            path: 'requests',
            element: <RequestsPage />,
          },
          {
            path: 'requests/:id',
            element: <RequestDetailsPage />,
          },
        ],
      },
    ],
  },
];
