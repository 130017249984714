/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './translations/en';
import { de } from './translations/de';
import { es } from './translations/es';

export const DEFAULT_LANGUAGE = 'en';
const supportedLanguages = ['es', 'en', 'de'];

function getBrowserLanguage(): string {
  return navigator?.language?.split?.('-')?.[0];
}

const browserLanguage = getBrowserLanguage();

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    de: { translation: de },
    es: { translation: es },
  },
  lng: supportedLanguages.includes(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});
