export const tokens = {
  prefix: {
    prefixCompany: 'prefix.prefixCompany',
  },
  suffix: {
    suffixCompanyPlural: 'suffix.suffixCompanyPlural',
    suffixCompanySingular: 'suffix.suffixCompanySingular',
  },
  common: {
    languageChanged: 'common.languageChanged',
    termsConditions: 'common.termsConditions',
    close: 'common.close',
    save: 'common.save',
    companyName: 'common.companyName',
    address: 'common.address',
    website: 'common.website',
    phoneNumber: 'common.phoneNumber',
    sector: 'common.sector',
    email: 'common.email',
    keywords: 'common.keywords',
    companyStatement: 'common.companyStatement',
    edit: 'common.edit',
    name: 'common.name',
    actions: 'common.actions',
    delete: 'common.delete',
    search: 'common.search',
    details: 'common.details',
    sendInterviews: 'common.sendInterviews',
    stateCountry: 'common.location',
    typeHere: 'common.typeHere',
    numberOfBusinesses: 'common.numberOfBusinesses',
    uploading: 'common.uploading',
    cancel: 'common.cancel',
    submit: 'common.submit',
    submitting: 'common.submitting',
  },
  banner: {
    enrichmentTitle: 'banner.enrichmentTitle',
    enrichmentBody: 'banner.enrichmentBody',
    enrichmentError: 'banner.enrichmentError',
    enrichmentLoading: 'banner.enrichmentLoading',
    enrichmentUpdatedAt: 'banner.enrichmentUpdatedAt',
  },
  nav: {
    dashboard: 'nav.dashboard',
    error: 'nav.error',
    login: 'nav.login',
    register: 'nav.register',
  },
  tabs: {
    members: 'tabs.members',
    events: 'tabs.events',
  },
  dashboard: {
    tiles: {
      // Organization Index
      totalManufacturers: 'dashboard.chambers.totalManufacturers',
      totalPaidUsers: 'dashboard.chambers.totalPaidUsers',
      totalMeetingsArranged: 'dashboard.chambers.totalMeetingsArranged',
      commoditiesNeeded: 'dashboard.chambers.commoditiesNeeded',

      totalMembers: 'dashboard.chambers.totalMembers',
      totalThreshold: 'dashboard.chambers.totalThreshold',
      membersEnriched: 'dashboard.chambers.membersEnriched',
      overallSupplyChain: 'dashboard.chambers.overallSupplyChain',
      sectorDistribution: 'dashboard.chambers.sectorDistribution',
      topSectors: 'dashboard.chambers.topSectors',
      averageCompanySize: 'dashboard.chambers.averageCompanySize',
      demoDistribution: 'dashboard.chambers.demoDistribution',
      onlinePresence: 'dashboard.chambers.onlinePresence',
      totalMembersBackTile: 'dashboard.chambers.totalMembersBackTile',
      successfullyEnriched: 'dashboard.chambers.successfullyEnriched',
      noSuccessfullyEnriched: 'dashboard.chambers.noSuccessfullyEnriched',
      failedEnriched: 'dashboard.chambers.failedEnriched',
      awaitingEnrichment: 'dashboard.chambers.awaitingEnrichment',
      progressEnrichment: 'dashboard.chambers.progressEnrichment',
      enrichmentNotPossible: 'dashboard.chambers.enrichmentNotPossible',
      noCurrentEnrichment: 'dashboard.chambers.noCurrentEnrichment',
      sectorDistributionTileBackHeading: 'dashboard.chambers.sectorDistributionTileBackHeading',
      averageCompanyTileBackHeading: 'dashboard.chambers.averageCompanyTileBackHeading',
      presenceNotClaimed: 'dashboard.chambers.presenceNotClaimed',
      presenceAllClaimed: 'dashboard.chambers.presenceAllClaimed',
      presenceClaimedCount0: 'dashboard.chambers.presenceClaimedCount0',
      presenceClaimedCount: 'dashboard.chambers.presenceClaimedCount',
    },
  },
  welcome: {
    title: 'welcome.title',
    subtitle: 'welcome.subtitle',
    prompt1: 'welcome.prompt',
    prompt2: 'welcome.prompt2',
    changes: 'welcome.changes',
    keywordBlur: 'welcome.keywordBlur',
    passwordPrompt: 'welcome.passwordPrompt',
    passwordMessage: 'welcome.passwordMessage',
    interview: {
      pressBarSpace: 'welcome.interview.pressBarSpace',
      pressButton: 'welcome.interview.pressButton',
      startInterview: 'welcome.interview.startInterview',
      resumeInterview: 'welcome.interview.resumeInterview',
    },
  },
  chat: {
    hi: 'chat.hi',
    leaveAMessage: 'chat.leaveAMessage',
    openingMessage: 'chat.openingMessage',
    openingMessageDemo3: 'chat.openingMessageDemo3',

    conversationStarterPrimary1: 'chat.conversationStarterPrimary1',
    conversationStarterSecondary1: 'chat.conversationStarterSecondary1',
    conversationStarterPrimary2: 'chat.conversationStarterPrimary2',
    conversationStarterSecondary2: 'chat.conversationStarterSecondary2',
    conversationStarterPrimary3: 'chat.conversationStarterPrimary3',
    conversationStarterSecondary3: 'chat.conversationStarterSecondary3',
    conversationStarterPrimary4: 'chat.conversationStarterPrimary4',
    conversationStarterSecondary4: 'chat.conversationStarterSecondary4',

    chatLoaderMessage1: 'chat.chatLoaderMessage1',
    chatLoaderMessage2: 'chat.chatLoaderMessage2',
    chatLoaderMessage3: 'chat.chatLoaderMessage3',
    chatLoaderMessage4: 'chat.chatLoaderMessage4',
  },
  chambers: {
    memberList: 'chambers.memberList',
    sendInterview: 'chambers.sendInterview',
  },
  errors: {
    dashboardAPI: 'errors.dashboardAPI',
  },
  feedback: {
    sendFeedback: 'feedback.sendFeedback',
    title: 'feedback.title',
    placeholder: 'feedback.placeholder',
    submitSuccess: 'feedback.submitSuccess',
    submitFailure: 'feedback.submitFailure',
  },
  companySizeMap: {
    a: 'companySizeMap.a',
    b: 'companySizeMap.b',
    c: 'companySizeMap.c',
    d: 'companySizeMap.d',
    e: 'companySizeMap.e',
    f: 'companySizeMap.f',
    g: 'companySizeMap.g',
    h: 'companySizeMap.h',
    i: 'companySizeMap.i',
  },
};
