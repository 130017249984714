import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SMBBusiness } from 'src/types/organization';

type InteractionStyleAction = PayloadAction<string | null>;
type OnboardingAction = PayloadAction<boolean>;
type SetBusinessProfileAction = PayloadAction<SMBBusiness>;
type SetHasCompetitorsAction = PayloadAction<boolean>;

interface State {
  interactionStyle: string | null;
  hasMembers: boolean; // Chambers only
  businessProfile: SMBBusiness | null;
  hasCompetitors: boolean | null;
}

const initialState: State = {
  interactionStyle: null,
  hasMembers: false,
  businessProfile: null,
  hasCompetitors: null,
};

const reducers = {
  setInteractionStyle(state: State, action: InteractionStyleAction): void {
    state.interactionStyle = action.payload;
  },
  setHasMembers(state: State, action: OnboardingAction): void {
    state.hasMembers = action.payload;
  },
  setBusinessProfile(state: State, action: SetBusinessProfileAction): void {
    state.businessProfile = action.payload;
  },
  setHasCompetitors(state: State, action: SetHasCompetitorsAction): void {
    state.hasCompetitors = action.payload;
  },
};

export const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers,
});

export const { reducer } = slice;
