import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  Attendee,
  AttendeeAgenda,
  AttendeeAgendaByTime,
  MeetingCommodity,
  EventSMB,
  PaymentStatus,
} from 'src/types/event-planner';
import { Commodity, Meeting, SMBBusiness } from 'src/types/organization';

type SetCommoditiesCatalogAction = PayloadAction<Commodity[]>;
type SetSupplierCommoditiesAction = PayloadAction<MeetingCommodity[]>;
type SetSupplierAttendeesAction = PayloadAction<EventSMB[]>;
type IsUserEnrolledAction = PayloadAction<boolean>;
type SetSupplierAttendeeAction = PayloadAction<Attendee | null>;
type IsLoadingSupplierAttendeeAction = PayloadAction<boolean>;
type AddSMBToCalendarAction = PayloadAction<{
  smbId: string;
  agenda: Record<string, AttendeeAgendaByTime>;
}>;
type RemoveSMBFromCalendarAction = PayloadAction<{
  smbId: string;
}>;
type SetSupplierAgendaAction = PayloadAction<AttendeeAgenda>;
type SetSMBDetailsAction = PayloadAction<SMBBusiness>;
type SetPaymentStatusAction = PayloadAction<PaymentStatus>;
type SetMeetingByTimeSlotAction = PayloadAction<{ timeSlot: string; meetings: Meeting[] }>;
type SetCommoditiesByMeetingIdAction = PayloadAction<{
  smbId: string;
  commodities: Commodity[];
}>;
type UpdateAttendeeNotesAction = PayloadAction<string>;

interface EnterpriseEventState {
  isUserEnrrolled: boolean;
  enterpriseSMBs: EventSMB[];
  commoditiesCatalog: Commodity[];
  calendarBySMBId: Record<string, Record<string, AttendeeAgendaByTime>>;
  meetingsByTimeSlot: Record<
    string,
    {
      meetings: Meeting[];
    }
  >;
  commoditiesBySMBId: Record<string, Commodity[]>;
  supplierAgenda: AttendeeAgenda | null;
  smbDetails: SMBBusiness | null;
  paymentStatus: PaymentStatus | null;
  supplierAttendee: Attendee | null;
  isLoadingSupplierAttendee: boolean;
}

const initialState: EnterpriseEventState = {
  isUserEnrrolled: false,
  enterpriseSMBs: [],
  commoditiesCatalog: [],
  calendarBySMBId: {},
  supplierAgenda: null,
  smbDetails: null,
  paymentStatus: null,
  meetingsByTimeSlot: {},
  commoditiesBySMBId: {},
  supplierAttendee: null,
  isLoadingSupplierAttendee: false,
};

const reducers = {
  setCommoditiesCatalog(state: EnterpriseEventState, action: SetCommoditiesCatalogAction): void {
    state.commoditiesCatalog = action.payload;
  },
  setSupplierCommodities(state: EnterpriseEventState, action: SetSupplierCommoditiesAction): void {
    if (state.supplierAttendee?.commodities) {
      state.supplierAttendee.commodities = action.payload;
    } else {
      state.supplierAttendee = {
        ...state.supplierAttendee,
        commodities: action.payload,
      } as Attendee;
    }
  },
  setUserEnroll(state: EnterpriseEventState, action: IsUserEnrolledAction): void {
    state.isUserEnrrolled = action.payload;
  },
  setSupplierAttendee(state: EnterpriseEventState, action: SetSupplierAttendeeAction): void {
    state.supplierAttendee = action.payload;
  },
  isLoadingSupplierAttendee(
    state: EnterpriseEventState,
    action: IsLoadingSupplierAttendeeAction
  ): void {
    state.isLoadingSupplierAttendee = action.payload;
  },
  setEnterpriseSMBs(state: EnterpriseEventState, action: SetSupplierAttendeesAction): void {
    state.enterpriseSMBs = action.payload;
  },
  addSMBToCalendar(state: EnterpriseEventState, action: AddSMBToCalendarAction): void {
    state.calendarBySMBId[action.payload.smbId] = action.payload.agenda;
  },
  removeSMBFromCalendar(state: EnterpriseEventState, action: RemoveSMBFromCalendarAction): void {
    delete state.calendarBySMBId[action.payload.smbId];
  },
  setSupplierAgenda(state: EnterpriseEventState, action: SetSupplierAgendaAction): void {
    state.supplierAgenda = action.payload;
  },
  setSMBDetails(state: EnterpriseEventState, action: SetSMBDetailsAction): void {
    state.smbDetails = action.payload;
  },
  setPaymentStatus(state: EnterpriseEventState, action: SetPaymentStatusAction): void {
    state.paymentStatus = action.payload;
  },
  setMeetingsByTimeSlot(state: EnterpriseEventState, action: SetMeetingByTimeSlotAction): void {
    state.meetingsByTimeSlot[action.payload.timeSlot] = {
      meetings: action.payload.meetings,
    };
  },
  setCommoditiesBySMBId(
    state: EnterpriseEventState,
    action: SetCommoditiesByMeetingIdAction
  ): void {
    state.commoditiesBySMBId[action.payload.smbId] = action.payload.commodities;
  },
  updateAttendeeNotes(state: EnterpriseEventState, action: UpdateAttendeeNotesAction): void {
    if (state.supplierAttendee) {
      state.supplierAttendee.notes = action.payload;
    }
  },
};

export const slice = createSlice({
  name: 'supplierEventPlanner',
  initialState,
  reducers,
});

export const { reducer } = slice;
