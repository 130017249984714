import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { AuthChamberGuard } from 'src/guards/user-role-permissions';
import PreFetch from 'src/pages/v2-chambers/welcome-intro/pre-fetch';

const AIWelcomeIntroPage = lazy(() => import('src/pages/v2-chambers/welcome-intro'));
const WelcomeIntroOnboardingPage = lazy(
  () => import('src/pages/v2-chambers/welcome-intro/onboarding')
);
const UploadMembersPage = lazy(() => import('src/pages/v2-chambers/welcome-intro/upload-members'));
const UploadMemoryPage = lazy(() => import('src/pages/v2-chambers/welcome-intro/upload-memory'));

export const welcomeChamberRoutes: RouteObject[] = [
  {
    path: 'welcome-chamber',
    element: (
      <AuthChamberGuard>
        <PreFetch>
          <Suspense>
            <Outlet />
          </Suspense>
        </PreFetch>
      </AuthChamberGuard>
    ),
    children: [
      {
        index: true,
        element: <AIWelcomeIntroPage />,
      },
      {
        path: 'onboarding',
        element: <WelcomeIntroOnboardingPage />,
      },
      {
        path: 'upload-members',
        element: <UploadMembersPage />,
      },
      {
        path: 'upload-memory',
        element: <UploadMemoryPage />,
      },
    ],
  },
];
