import { type Theme, useMediaQuery } from '@mui/material';
import type { CSSProperties, FC } from 'react';

export const Logo: FC<{ noBackground?: boolean; blackLogo?: boolean; style?: CSSProperties }> = (
  props
) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (props.blackLogo) {
    return (
      <img
        src="/assets/logo_only_black.svg"
        style={{ ...props.style }}
      />
    );
  }

  if (props.noBackground) {
    return <img src="/assets/logo_only.svg" />;
  }

  return (
    <img
      style={{
        height: smDown ? '34px' : '45px',
        width: smDown ? '150px' : '200px',
        ...props.style
      }}
      src="/assets/LevelPlane.png"
    />
  );
};
