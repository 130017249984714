import { subHours, subMinutes } from 'date-fns';

import type { Contact, Thread } from 'src/types/chat';

const now = new Date();
export const CHAT_AI_AUTHOR_KEY = '5e86805e2bafd54f66cc95c3';
export const CHAT_AI_THREAD_ID = '5e867eb9de721aecaccf4f7b';
export const LOADER_ICON = 'LOADER_ICON';
export const CHAT_USER_GENERIC_KEY = '000000000000000000000000';

export const contacts: Contact[] = [
  {
    id: '5e8891ab188cd2855e6029b7',
    avatar: '/assets/avatars/avatar-alcides-antonio.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'Alcides Antonio',
  },
  {
    id: '5e887a62195cc5aef7e8ca5d',
    avatar: '/assets/avatars/avatar-marcus-finn.png',
    isActive: false,
    lastActivity: subHours(now, 2).getTime(),
    name: 'Marcus Finn',
  },
  {
    id: '5e887ac47eed253091be10cb',
    avatar: '/assets/avatars/avatar-carson-darrin.png',
    isActive: false,
    lastActivity: subMinutes(now, 15).getTime(),
    name: 'Carson Darrin',
  },
  {
    id: '5e887b209c28ac3dd97f6db5',
    avatar: '/assets/avatars/avatar-fran-perez.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'Fran Perez',
  },
  {
    id: '5e887b7602bdbc4dbb234b27',
    avatar: '/assets/avatars/avatar-jie-yan-song.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'Jie Yan Song',
  },
  {
    id: CHAT_AI_AUTHOR_KEY,
    avatar: '/assets/avatars/avatar-seo-hyeon-ji.png',
    isActive: false,
    lastActivity: subHours(now, 1).getTime(),
    name: 'Arti',
  },
  {
    id: '5e887a1fbefd7938eea9c981',
    avatar: '/assets/avatars/avatar-penjani-inyene.png',
    isActive: false,
    lastActivity: subHours(now, 6).getTime(),
    name: 'Penjani Inyene',
  },
  {
    id: '5e887d0b3d090c1b8f162003',
    avatar: '/assets/avatars/avatar-omar-darboe.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'Omar Darobe',
  },
  {
    id: '5e88792be2d4cfb4bf0971d9',
    avatar: '/assets/avatars/avatar-siegbert-gottfried.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'Siegbert Gottfried',
  },
  {
    id: '5e8877da9a65442b11551975',
    avatar: '/assets/avatars/avatar-iulia-albu.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'Iulia Albu',
  },
  {
    id: '5e8680e60cba5019c5ca6fda',
    avatar: '/assets/avatars/avatar-nasimiyu-danai.png',
    isActive: true,
    lastActivity: now.getTime(),
    name: 'Nasimiyu Danai',
  },
];

export const threads: Thread[] = [
  {
    id: '5e867eb9de721aecaccf4f7b',
    messages: [
      {
        id: '5e867f0a5bc0ff2bfa07bfa6',
        attachments: [],
        body: '<Will be overridden>',
        contentType: 'welcome',
        createdAt: subMinutes(now, 1).getTime(),
        authorId: CHAT_AI_AUTHOR_KEY,
        read: false,
      },
      // {
      //   id: '5e867f0a5bc0ff2bfa07bfa7',
      //   attachments: [],
      //   body: "I'm looking for a steel provider in Los Altos de Jalisco",
      //   contentType: 'text',
      //   createdAt: subMinutes(now, 1).getTime(),
      //   authorId: '5e86809283e28b96d2d38537',
      // },
      // {
      //   id: '5e867f0a5bc0ff2bfa07bfa8',
      //   attachments: [],
      //   body: "We don't have any steel service provider located at that specific area. Would you like to share your email so I can notify you once we have a service provider for you?",
      //   contentType: 'email_question',
      //   createdAt: subMinutes(now, 1).getTime(),
      //   authorId: CHAT_AI_AUTHOR_KEY,
      // },
    ],
    participantIds: ['5e86809283e28b96d2d38537', CHAT_AI_AUTHOR_KEY],
    type: 'ONE_TO_ONE',
    unreadCount: 2,
  },
];

type DemoChatStruct = {
  respondTo: string;
  chatResponse: string;
  userResponse: string;
};

type DemoChat = {
  chat: DemoChatStruct[];
};

export const userQuestions = [
  'start demo1',
  'My company builds electric scooters and I need a sheet metal parts.',
  'Jalisco.',
  'Sure, they should have automotive experience if possible.',
  'Actually, there should be more than 100 employees.',
  'Show me a few more.',
  'Give me more information about Fosa.',
];

export const userQuestionsSpanish = [
  'start demo2',
  'Mi empresa construye scooters eléctricos y necesito piezas de metal.',
  'Jalisco.',
  'Claro, deberían tener experiencia en automotriz si es posible.',
  'De hecho, debería haber más de 100 empleados.',
  'Muéstrame algunos más.',
  'Dame más información sobre Fosa.',
];

export const userQuestionsDubai = [
  'start demo3',
  'I want to start a business in Dubai.',
  'Fintech.',
  'Yes.',
  'LevelPlane',
  'San Francisco, California.',
  'Information and communication.',
  'B2B.',
  'Around 5 million USD.',
  'Dubai Chamber Digital.',
  'Knowledge of the market size in Dubai.',
  'Yes',
];

export const demoConversation: Record<string, DemoChat> = {
  demo1: {
    chat: [
      {
        respondTo: userQuestions[0],
        chatResponse: 'Starting demo!',
        userResponse: userQuestions[1],
      },
      {
        respondTo: userQuestions[1],
        chatResponse:
          'Ok, I know of 272 metalworking suppliers. Can you help me narrow down the list? For example, what region?',
        userResponse: userQuestions[2],
      },
      {
        respondTo: userQuestions[2],
        chatResponse: 'There are 67 in Jalisco. Can you provide any other details?',
        userResponse: userQuestions[3],
      },
      {
        respondTo: userQuestions[3],
        chatResponse: `
Here are some options:
- **Advanced Engineering Systems**
  - Location: Guadalajara, Jalisco, México
  - Phones: 3336456976 | 3334415533
  - Email: engineering_systems@yahoo.com
  - Website: [http://aes-mx.com/](http://aes-mx.com/)

- **PM Stamping**
  - Location: San Pedro Tlaquepaque, Jalisco, México
  - Phones: 3336662708 | 3311419110
  - Email: julian.gomez@pmstamping.com
  - Website: [https://www.pmstamping.com](https://www.pmstamping.com)

- **Piamsa**
  - Location: Celaya, Guanajuato, México
  - Phone: 3336844265
  - Email: jorgecervantes@prodigy.net.mx
  - Website: [https://www.hclpiamsa.com](https://www.hclpiamsa.com)

There are 19 other automotive metal working companies in Jalisco. Are there any other requirements
        `,
        userResponse: userQuestions[4],
      },
      {
        respondTo: userQuestions[4],
        chatResponse: `
- **dfm diseño y fabricaciones metálicas sa de cv**
  - Location: Guadalajara, Jalisco, México
  - Phone: 3332681111
  - Email: patricia.rubio@grupodfm.com.mx
  - Website: [https://www.grupodfm.com.mx](https://www.grupodfm.com.mx)

- **Beccar**
  - Location: Zapotlanejo, Jalisco, México
  - Phones: 3737356030 | 3331872001
  - Email: manuel.delatorre@beccar.com.mx
  - Website: [beccar.com.mx](beccar.com.mx)

- **Mannesmann Precision Tubes Mexico**
  - Location: El Salto, Jalisco, México
  - Phones: 3336881107 | 3316028694
  - Email: blas.baez@mannesmann.com
  - Website: [https://www.mannesmann-precision-tubes.com/](https://www.mannesmann-precision-tubes.com/)
    
These suppliers are the best fit. Can I help you with anything else?
        `,
        userResponse: userQuestions[5],
      },
      {
        respondTo: userQuestions[5],
        chatResponse: `
- **Seerauber Automotive**
  - Location: Guadalajara, Jalisco, México
  - Phones: 523336971774 | 3331153330
  - Email: angel.cifuentes@seerauber.com
  - Website: [http://www.seerauber.com/](http://www.seerauber.com/)

- **FOSA**
  - Location: Guadalajara, Jalisco, México
  - Phones: 3338120660 | 3311733244
  - Email: gestionfosa@gmail.com
  - Website: [https://WWW.FOSA.COM.MX](https://WWW.FOSA.COM.MX)

- **Valcon**
  - Location: San Pedro Tlaquepaque, Jalisco, México
  - Phones: 3336660055 | 3331155507
  - Email: valcon@valcon.mx
  - Website: [valcon.mx](valcon.mx)

Can I help you with anything else?
        `,
        userResponse: userQuestions[6],
      },
      {
        respondTo: userQuestions[6],
        chatResponse: `
- **FOSA**
  - Location: Guadalajara, Jalisco, México
  - Phones: 3338120660 | 3311733244
  - Email: gestionfosa@gmail.com
  - Website: [https://WWW.FOSA.COM.MX](https://WWW.FOSA.COM.MX)


FOSA is a company in the metal-mechanic industry. They offer a range of services and products including
furnaces for casting, induction furnaces for iron and steel fusion, automatic sand molders, sand mixer
for self-hardening molds, manual core makers, automatic core makers, centrifuges for tube manufacturing,
conventional lathes for machining, CNC lathes for machining, CNC machining centers, induction casting furnaces,
calipers, micrometers, squares, thread gauges, height comparators, dial indicators, Rockwell hardness testers,
metallographic microscopes, metallographic polishers, optical emission spectrometers, universal testing
machines, green sand moisture meters, green sand mold hardness testers, self-hardening mold hardness testers.


If you need more specific information, please let me know.
        `,
        userResponse: '',
      },
    ],
  },
  demo2: {
    chat: [
      {
        respondTo: userQuestionsSpanish[0],
        chatResponse: 'Starting demo!',
        userResponse: userQuestionsSpanish[1],
      },
      {
        respondTo: userQuestionsSpanish[1],
        chatResponse:
          'Ok, conozco 272 proveedores de metalurgia. ¿Puedes ayudarme a reducir la lista? Por ejemplo, ¿qué región?',
        userResponse: userQuestionsSpanish[2],
      },
      {
        respondTo: userQuestionsSpanish[2],
        chatResponse: 'Hay 67 en Jalisco. ¿Puedes proporcionar algún otro detalle?',
        userResponse: userQuestionsSpanish[3],
      },
      {
        respondTo: userQuestionsSpanish[3],
        chatResponse: `
Aquí hay algunas opciones:
- **Advanced Engineering Systems**
  - Ubicación: Guadalajara, Jalisco, México
  - Teléfonos: 3336456976 | 3334415533
  - Correo electrónico: engineering_systems@yahoo.com
  - Sitio web: [http://aes-mx.com/](http://aes-mx.com/)

- **PM Stamping**
  - Ubicación: San Pedro Tlaquepaque, Jalisco, México
  - Teléfonos: 3336662708 | 3311419110
  - Correo electrónico: julian.gomez@pmstamping.com
  - Sitio web: [https://www.pmstamping.com](https://www.pmstamping.com)

- **Piamsa**
  - Ubicación: Celaya, Guanajuato, México
  - Teléfono: 3336844265
  - Correo electrónico: jorgecervantes@prodigy.net.mx
  - Sitio web: [https://www.hclpiamsa.com](https://www.hclpiamsa.com)

Hay 19 otras empresas de metalurgia automotriz en Jalisco. ¿Hay algún otro requisito?
        `,
        userResponse: userQuestionsSpanish[4],
      },
      {
        respondTo: userQuestionsSpanish[4],
        chatResponse: `
- **dfm diseño y fabricaciones metálicas sa de cv**
  - Ubicación: Guadalajara, Jalisco, México
  - Teléfono: 3332681111
  - Correo electrónico: patricia.rubio@grupodfm.com.mx
  - Sitio web: [https://www.grupodfm.com.mx](https://www.grupodfm.com.mx)

- **Beccar**
  - Ubicación: Zapotlanejo, Jalisco, México
  - Teléfonos: 3737356030 | 3331872001
  - Correo electrónico: manuel.delatorre@beccar.com.mx
  - Sitio web: [beccar.com.mx](beccar.com.mx)

- **Mannesmann Precision Tubes Mexico**
  - Ubicación: El Salto, Jalisco, México
  - Teléfonos: 3336881107 | 3316028694
  - Correo electrónico: blas.baez@mannesmann.com
  - Sitio web: [https://www.mannesmann-precision-tubes.com/](https://www.mannesmann-precision-tubes.com/)
    
Estos proveedores son los más adecuados. ¿Puedo ayudarte en algo más?
        `,
        userResponse: userQuestionsSpanish[5],
      },
      {
        respondTo: userQuestionsSpanish[5],
        chatResponse: `
- **Seerauber Automotive**
  - Ubicación: Guadalajara, Jalisco, México
  - Teléfonos: 523336971774 | 3331153330
  - Correo electrónico: angel.cifuentes@seerauber.com
  - Sitio web: [http://www.seerauber.com/](http://www.seerauber.com/)

- **FOSA**
  - Ubicación: Guadalajara, Jalisco, México
  - Teléfonos: 3338120660 | 3311733244
  - Correo electrónico: gestionfosa@gmail.com
  - Sitio web: [https://WWW.FOSA.COM.MX](https://WWW.FOSA.COM.MX)

- **Valcon**
  - Ubicación: San Pedro Tlaquepaque, Jalisco, México
  - Teléfonos: 3336660055 | 3331155507
  - Correo electrónico: valcon@valcon.mx
  - Sitio web: [valcon.mx](valcon.mx)

¿Puedo ayudarte en algo más?
        `,
        userResponse: userQuestionsSpanish[6],
      },
      {
        respondTo: userQuestionsSpanish[6],
        chatResponse: `
- **FOSA**
  - Ubicación: Guadalajara, Jalisco, México
  - Teléfonos: 3338120660 | 3311733244
  - Correo electrónico: gestionfosa@gmail.com
  - Sitio web: [https://WWW.FOSA.COM.MX](https://WWW.FOSA.COM.MX)

FOSA es una empresa en la industria metal-mecánica. Ofrecen una gama de servicios y productos incluyendo
hornos para fundición, hornos de inducción para fusión de hierro y acero, moldes de arena automáticos, mezcladores de arena
para moldes autoendurecibles, fabricantes de núcleos manuales, fabricantes de núcleos automáticos, centrifugadoras para la fabricación
de tubos, tornos convencionales para mecanizado, tornos CNC para mecanizado, centros de mecanizado CNC, hornos de fundición por inducción,
calibradores, micrómetros, escuadras, calibres de rosca, comparadores de altura, indicadores de cuadrante, probadores de dureza Rockwell,
microscopios metalográficos, pulidoras metalográficas, espectrómetros de emisión óptica, máquinas de ensayo universales, medidores de humedad
de arena verde, probadores de dureza de moldes de arena verde, probadores de dureza de moldes autoendurecibles.

Si necesitas más información específica, házmelo saber.
        `,
        userResponse: '',
      },
    ],
  },
  demo3: {
    chat: [
      {
        respondTo: userQuestionsDubai[0],
        chatResponse: 'Starting demo!',
        userResponse: userQuestionsDubai[1],
      },
      {
        respondTo: userQuestionsDubai[1],
        chatResponse: 'What kind of business?',
        userResponse: userQuestionsDubai[2],
      },
      {
        respondTo: userQuestionsDubai[2],
        chatResponse: `
Great! Dubai is home to 60% of all Fintech companies based on in the Gulf region

This is the guide to setup a Fintech business in Dubai:

- [ ] 1 Identify your business activity
- [ ] 2 Know the regulations
- [ ] 3 Pick your business structure
- [ ] 4 Apply for trade license
- [ ] 5 Additional approvals
- [ ] 6 Visa applications
- [ ] 7 Open a bank account

The first step is to gather essential information about your business and your expansion plans in Dubai.

Do you want to proceed with Step 1?
        `,
        userResponse: userQuestionsDubai[3],
      },
      {
        respondTo: userQuestionsDubai[3],
        chatResponse: 'Let’s start with the name of your business',
        userResponse: userQuestionsDubai[4],
      },
      {
        respondTo: userQuestionsDubai[4],
        chatResponse: 'What’s your city and country?',
        userResponse: userQuestionsDubai[5],
      },
      {
        respondTo: userQuestionsDubai[5],
        chatResponse:
          'Please, tell me about the industry sector that best represents your tech business?',
        userResponse: userQuestionsDubai[6],
      },
      {
        respondTo: userQuestionsDubai[6],
        chatResponse: 'What’s your type of business mode (B2B, B2C, B2G)?',
        userResponse: userQuestionsDubai[7],
      },
      {
        respondTo: userQuestionsDubai[7],
        chatResponse: 'What’s your annual revenue?',
        userResponse: userQuestionsDubai[8],
      },
      {
        respondTo: userQuestionsDubai[8],
        chatResponse:
          'Are there specific entities in Dubai that you would ideally like to be introduced to?',
        userResponse: userQuestionsDubai[9],
      },
      {
        respondTo: userQuestionsDubai[9],
        chatResponse: 'What areas you would like us to support your growth efforts in Dubai',
        userResponse: userQuestionsDubai[10],
      },
      {
        respondTo: userQuestionsDubai[10],
        chatResponse: `
You have now completed Step 1!

- [x] 1 Identify your business activity
- [ ] 2 Know the regulations
- [ ] 3 Pick your business structure
- [ ] 4 Apply for trade license
- [ ] 5 Additional approvals
- [ ] 6 Visa applications
- [ ] 7 Open a bank account

Would you like to proceed to Step 2?
        `,
        userResponse: userQuestionsDubai[11],
      },
      {
        respondTo: userQuestionsDubai[11],
        chatResponse: 'Great! Let’s go over the regulations',
        userResponse: '',
      },
    ],
  },
  demo4: {
    chat: [
      {
        respondTo: 'start demo4',
        chatResponse: 'Starting demo!',
        userResponse: 'I want to know the market size for Logistics in Dubai.',
      },
      {
        respondTo: 'I want to know the market size for Logistics in Dubai.',
        chatResponse:
          'Dubai boasts a robust logistics sector with 657 registered logistic companies. To provide a more precise market size, could you specify a particular segment or service within logistics?',
        userResponse: 'My business is in warehousing.',
      },
      {
        respondTo: 'My business is in warehousing.',
        chatResponse:
          'I know 562 warehouses in Dubai, can you help me narrow down the list? For example, warehouse size?',
        userResponse: 'Less than 1000 mt2',
      },
      {
        respondTo: 'Less than 1000 mt2',
        chatResponse: 'There are 126 with less than 1000 mt2. Can you provide any other details?',
        userResponse: 'They should have space for at least five 40ft container cargo trucks',
      },
      {
        respondTo: 'They should have space for at least five 40ft container cargo trucks',
        chatResponse: `
- **Al Futtaim Logistics Company (L.L.C)**
  - Location: Dubai
  - Phones: +971-4-8818288
  - Website: [www.aflogistics.com](www.aflogistics.com)

- **A L S Global Freight Services L.L.C**
  - Location: Dubai
  - Phones: +971-4-8833939
  - Website: [www.alogistics.biz](www.alogistics.biz)

- **C P World (L.L.C)**
  - Location: Dubai
  - Phones: +971-4-3343300
  - Website: [cpworldgroup.com/](cpworldgroup.com/)
        `,
        userResponse: 'Give me more information about Al Futtaim Logistics Company',
      },
      {
        respondTo: 'Give me more information about Al Futtaim Logistics Company',
        chatResponse: `
- **Al Futtaim Logistics Company (L.L.C)**
  - Location: Dubai
  - Phones: +971-4-8818288
  - Website: [www.aflogistics.com](www.aflogistics.com)

They provide integrated solutions for companies in Automotive, Retail, Fashion, Food & Beverages, Industrial, Exhibitions & Events, E-Commerce & Home Delivery/ Assembly, and Special Projects
Our service capabilities extend to Global Freight Forwarding & Local Services, Warehousing & Contract Logistics, Local and Cross Border Transportation, Corporate Transportation, Domestic & International Relocations, Finished Vehicle Distribution, Lead Logistics and 4PL.
The company manages over 1.2 million square meters of ambient & cold store warehousing and open yards with its own fleet and operational centres located in major air and sea hubs in the UAE.
The company has won a host of awards for its Leadership in Innovation and Excellence in Automotive & Retail Supply Chain in the Middle East.
        `,
        userResponse: 'Ok, how do I start a logistic company in Dubai?',
      },
      {
        respondTo: 'Ok, how do I start a logistic company in Dubai?',
        chatResponse: `
Perfect! These are the 7 steps to start a Logistic company in Dubai:

- [ ] 1 Identify your business
- [ ] 2 Know the regulations
- [ ] 3 Pick your business structure
- [ ] 4 Apply for trade license
- [ ] 5 Additional approvals
- [ ] 6 Visa applications
- [ ] 7 Open a bank account


**Would you like to get started now?**       
        `,
        userResponse: 'Yes, I can start now.',
      },
      {
        respondTo: 'Yes, I can start now.',
        chatResponse:
          'If you have a corporate deck, you can upload it now and use it to answer some questions. Or, I can just ask you the questions now. Would you like to upload?',
        userResponse: '',
      },
      {
        respondTo: 'CorporateDeck.pdf',
        chatResponse: `
- **Company name:** ACME Corporation
- **City:** San Francisco
- **Country:** USA
- **Business sector:** Fintech
- **Revenue:** 5 million USD
- **Number of employees:** 100
- **Company headquarters:** San Francisco, California

Here is what we've captured for your company profile, is this there anything you would like to edit?
        `,
        userResponse: 'That is all correct',
      },
      {
        respondTo: 'That is all correct',
        chatResponse: `Ok, good. Are there specific entities in Dubai that you would ideally like to be introduced to?`,
        userResponse: 'Dubai Chamber Digital.',
      },
      {
        respondTo: 'Dubai Chamber Digital.',
        chatResponse: `What areas you would like the Dubai Chamber Digital support your growth in Dubai?`,
        userResponse: `Support in setting up logistically in Dubai.`,
      },
      {
        respondTo: `Support in setting up logistically in Dubai.`,
        chatResponse: `
Perfect! We've just completed Step 1

- [x] 1 Identify your business activity

**Would you like to proceed to Step 2?**

- [ ] 2 Know the regulations
`,
        userResponse: `Sure, let's keep going`,
      },
      {
        respondTo: `Sure, let's keep going`,
        chatResponse: `Great! Let's go over the regulations`,
        userResponse: '',
      },
    ],
  },
};
