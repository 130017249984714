import { ReactNode, useContext, useEffect } from 'react';
import { DrawerContext } from '.';
import { useMounted } from 'src/hooks/use-mounted';

// Hook to use Drawer Context
export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawerContext must be used within a DrawerProvider');
  }
  return context;
};

// Hook for specific state and action access
export const useDrawerState = () => {
  const { state } = useDrawerContext();
  return state;
};

export const useDrawerActions = () => {
  const { dispatch } = useDrawerContext();

  const setDrawer = (component: ReactNode) => dispatch({ type: 'SET_DRAWER', payload: component });
  const setDrawerOpen = (open: boolean) => dispatch({ type: 'SET_DRAWER_OPEN', payload: open });
  const setDrawerWidth = (width: number) => dispatch({ type: 'SET_DRAWER_WIDTH', payload: width });

  return { setDrawer, setDrawerOpen, setDrawerWidth };
};

export const useDrawer = (
  component: ReactNode,
  drawerWidth: number,
  dependencies: unknown[] = []
) => {
  const isMounted = useMounted();
  const { dispatch } = useDrawerContext();

  useEffect(() => {
    if (isMounted()) {
      dispatch({ type: 'SET_DRAWER', payload: component });
      dispatch({ type: 'SET_DRAWER_WIDTH', payload: drawerWidth });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isMounted, ...dependencies]);
};
