import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CHAT_AI_AUTHOR_KEY, LOADER_ICON } from 'src/api/chat/data';
import { VITE_BASE_UI_URL } from 'src/config';
import { ChatThread, Message } from 'src/types/chat';
import { RequestDemand } from 'src/types/request-demand';
import { removeFromArray } from 'src/utils/array';
import { createResourceId } from 'src/utils/create-resource-id';
import { objFromArray } from 'src/utils/obj-from-array';

type GetThreadsAction = PayloadAction<{
  chats: ChatThread[];
  initialThread?: string | null;
  byRequestDemand?: Record<string, RequestDemand>;
}>;

type GetThreadAction = PayloadAction<ChatThread | null>;

type DeleteThreadAction = PayloadAction<string>;

type LoadThreadMessagesAction = PayloadAction<{ threadId: string; messages: Message[] }>;

type MarkThreadAsSeenAction = PayloadAction<string>;

type SetCurrentThreadAction = PayloadAction<string | undefined>;

type AddMessageAction = PayloadAction<{ message: Message; threadId: string }>;

type SetIsNewDemandAction = PayloadAction<boolean>;

type DemoModeAction = PayloadAction<{ isDemo: boolean; key: string }>;

type ConversationIndexAction = PayloadAction<number>;

interface V2MemberChatState {
  currentThreadId?: string;
  threads: {
    byId: Record<string, ChatThread>;
    allIds: string[];
    byRequestDemand: Record<string, RequestDemand>;
  };
  llmBackgroundActions: Message[];
  demo: {
    isDemoMode: boolean;
    key: string;
  };
  conversationIndex: number;
  isNewDemand: boolean;
}

const initialState: V2MemberChatState = {
  currentThreadId: undefined,
  threads: {
    allIds: [],
    byId: {},
    byRequestDemand: {},
  },
  llmBackgroundActions: [],
  demo: {
    isDemoMode: false,
    key: '',
  },
  conversationIndex: 0,
  isNewDemand: false,
};

const reducers = {
  getThreads(state: V2MemberChatState, action: GetThreadsAction): void {
    const threads = action.payload;
    state.threads.byId = objFromArray(threads.chats);
    state.threads.allIds = Object.keys(state.threads.byId);
    if (threads.byRequestDemand) {
      state.threads.byRequestDemand = threads.byRequestDemand;
    }
  },
  getThread(state: V2MemberChatState, action: GetThreadAction): void {
    const thread = action.payload;

    if (thread) {
      state.threads.byId[thread.id!] = thread;

      if (!state.threads.allIds.includes(thread.id!)) {
        state.threads.allIds.unshift(thread.id!);
      }
    }
  },
  deleteThread(state: V2MemberChatState, action: DeleteThreadAction): void {
    const threadId = action.payload;
    const threads = state.threads;
    // Remove from allIds
    state.threads.allIds = removeFromArray(
      threads.allIds.findIndex((id) => id === threadId),
      threads.allIds
    );
    // Remove from map byId
    delete threads.byId[threadId]
  },
  loadThreadMessages(state: V2MemberChatState, action: LoadThreadMessagesAction) {
    state.threads.byId[action.payload.threadId] = {
      ...state.threads.byId[action.payload.threadId],
      messages: action.payload.messages,
    };
  },
  markThreadAsSeen(state: V2MemberChatState, action: MarkThreadAsSeenAction): void {
    const threadId = action.payload;
    const thread = state.threads.byId[threadId];

    if (thread) {
      thread.unreadCount = 0;
    }
  },
  setCurrentThread(state: V2MemberChatState, action: SetCurrentThreadAction): void {
    state.currentThreadId = action.payload;

    // Mark messages from the thread as read
    if (state.currentThreadId) {
      state.threads.byId[state.currentThreadId].messages = state.threads.byId[
        state.currentThreadId
      ].messages.map((message) => {
        return {
          ...message,
          read: true,
        };
      });
    }
  },
  setIsNewDemand(state: V2MemberChatState, action: SetIsNewDemandAction): void {
    state.isNewDemand = action.payload;
  },
  addMessage(state: V2MemberChatState, action: AddMessageAction): void {
    const { threadId, message } = action.payload;
    const thread = state.threads.byId[threadId];
    const isDemoMode = state.demo.isDemoMode;
    const lastIndex = thread.messages.length - 1;

    // Remove loader message if any
    if (thread.messages[lastIndex]?.id === LOADER_ICON) {
      thread.messages = removeFromArray(lastIndex, thread.messages);
    }

    if (thread) {
      if (
        message.eventType === 'chunk_stream_response' ||
        message.eventType === 'complementary_response' ||
        message.eventType === 'end_stream_response'
      ) {
        thread.messages[lastIndex].body += message.body;
      } else if (message.eventType === 'intermediate_response') {
        state.llmBackgroundActions.push(message);
        // Remove loader if any
        if (thread.messages[lastIndex]?.id === LOADER_ICON) {
          thread.messages = removeFromArray(lastIndex, thread.messages);
        }
      } else {
        if (
          (message.eventType === 'begin_stream_response' ||
            message.eventType === 'full_response') &&
          thread.messages?.[lastIndex]?.authorId === CHAT_AI_AUTHOR_KEY
        ) {
          // Append message (we received another begin stream before user send a message)
          thread.messages[lastIndex].body += message.body;
        } else {
          thread.messages.push(message);
        }
      }

      if (message.eventType !== 'intermediate_response') {
        state.llmBackgroundActions = [];
      }
    }

    // Add loader message
    if (message.authorId !== CHAT_AI_AUTHOR_KEY) {
      thread.messages.push({
        attachments: [],
        authorId: CHAT_AI_AUTHOR_KEY,
        body: 'Gathering info...',
        contentType: 'loading',
        createdAt: new Date().getTime(),
        id: LOADER_ICON,
        read: false,
      });
    }

    // Auto-respond to the thread
    if (isDemoMode) {
      // Remove loader
      if (thread.messages[thread.messages.length - 1]?.id === LOADER_ICON) {
        thread.messages = removeFromArray(thread.messages.length - 1, thread.messages);
      }

      // Respond back
      const nextResponse =
        demos[state.demo.key][thread.messages[thread.messages.length - 1].body] ??
        "I'm sorry, I didn't understand";
      const { jsonData, clearedString } = extractJSONAndClearStatusTag(nextResponse);
      const { jsonData: memoryData, clearedString: str } =
        extractJSONAndClearMemoryTag(clearedString);

      thread.messages.push({
        attachments: [],
        authorId: CHAT_AI_AUTHOR_KEY,
        body: jsonData ? JSON.stringify(jsonData) : str,
        contentType: jsonData ? 'demand_status' : memoryData ? 'memory_update' : 'text',
        createdAt: new Date().getTime(),
        id: createResourceId(),
        read: false,
        eventType: action.payload.message.eventType,
        extraBody: memoryData ? JSON.stringify(memoryData) : undefined,
      });
      state.conversationIndex = state.conversationIndex + 1;
    }
  },
  setDemoMode(state: V2MemberChatState, action: DemoModeAction): void {
    state.demo.isDemoMode = action.payload.isDemo;
    state.demo.key = action.payload.key;
  },
  setConversationIndex(state: V2MemberChatState, action: ConversationIndexAction): void {
    state.conversationIndex = action.payload;
  },
};

interface ExtractionResult {
  jsonData: Record<string, any> | null;
  clearedString: string;
}
function extractJSONAndClearStatusTag(inputString: string): ExtractionResult {
  const regex = /<status>(.*?)<\/status>/;
  const match = inputString.match(regex);

  if (match && match[1]) {
    const clearedString = inputString.replace(regex, '');
    try {
      const json: Record<string, any> = JSON.parse(match[1]);
      return { jsonData: json, clearedString };
    } catch (e) {
      console.error('Extracted string is not valid JSON:', match[1]);
      return { jsonData: null, clearedString };
    }
  } else {
    console.log('No <status> tags found or no content inside <status> tags.');
    return { jsonData: null, clearedString: inputString };
  }
}
function extractJSONAndClearMemoryTag(inputString: string): ExtractionResult {
  const regex = /<update_memory>(.*?)<\/update_memory>/;
  const match = inputString.match(regex);

  if (match && match[1]) {
    const clearedString = inputString.replace(regex, '');
    try {
      const json: Record<string, any> = JSON.parse(match[1]);
      return { jsonData: json, clearedString };
    } catch (e) {
      console.error('Extracted string is not valid JSON:', match[1]);
      return { jsonData: null, clearedString };
    }
  } else {
    console.log('No <update_memory> tags found or no content inside <update_memory> tags.');
    return { jsonData: null, clearedString: inputString };
  }
}

export const slice = createSlice({
  name: 'v2MemberChat',
  initialState,
  reducers,
});

export const { reducer } = slice;

const demo1: Record<string, string> = {
  Hello: 'Hi! How can I help you today?',
  'We are expanding our operations and we need to ensure compliance with local, state, and federal employment laws. We are seeking legal support': `I can help you find legal support for your company by matching you with the best providers based on your requirements.
   
  - What industry is your company in?`,
  'We are an IT company':
    'Great! Can you tell me the different locations where you are doing business?',
  'California, New York, and Washington':
    'Good! Are you looking for services like **contract drafting, dispute resolution, and employment law compliance**?',
  'Yes, that is what I need!': 'What is your budget for legal services?',
  'I do not have a budget in mind right now': `No problem! I can provide different ranges, and you can decide later.
    
  I'll kick off the process to find the best match based on the information you just provided. [You can go to this page to see the progress of the search](${VITE_BASE_UI_URL}/dashboard/members/supplier-demand-status).`,
};

const demoSeller: Record<string, string> = {
  Hi: `Hello! We have an exciting news for you — someone is interested in potentially hiring your health insurance services.`,

  'That’s great! How can we proceed?':
    'To help us better match this lead with your services, I have a couple of quick questions. Would you like to proceed?',
  'Sure!': `Fantastic!

  - Could you please confirm that your plans cover the following: dental, vision, mental health, maternity, and newborn care?`,

  'That is correct, we offer plans that cover all of that': `Excellent!

  - Could you also provide a price range for these types of plans for an individual?`,

  'It is around $400 monthly but it may vary': `Thank you for the details! That's all the information I needed. You can now proceed to schedule a meeting with the buyer!`,
};
// memory_type
// title
// description
const demoHealthInsurance: Record<string, string> = {
  Status: `<status>{"title": "Cold-Rolled Steel Sheets Procurement", "subtitle":"Sourcing high-quality, ISO 9001 certified cold-rolled steel sheets for manufacturing.", "description":"This request involves finding a reliable supplier for 500 tons of cold-rolled steel sheets, specifically grade CR4 with a thickness of 2mm. The steel sheets are required for a manufacturing process, and the supplier must meet strict quality standards, including ISO 9001 certification. The preferred suppliers should be based within the US to ensure timely delivery, and the transaction will follow FOB shipping terms with net 30-day payment terms. The delivery timeline is critical, with a requirement to receive the steel sheets within 45 days.","requirements":"Material: Cold-rolled steel sheets, Grade: CR4, Thickness: 2mm, Quantity: 500 tons, Certification: ISO 9001, Preferred Location: Within the US, Delivery Timeline: Within 45 days, Payment Terms: Net 30 days, Shipping Terms: FOB"}</status>`,
  'I want to include new requirements in this request.': `Of course! I can help with that. What additional requirements would you like to add to your cold-rolled steel sheets request?`,
  'I need to specify the surface finish and packaging details.':
    'Got it! Let’s start with the surface finish. Do you have a specific type in mind, like smooth, matte, or polished?',
  'Yes, I need a smooth finish for these steel sheets.':
    '<update_memory>{"memory_type":"profile_update", "title":"Profile Updated","description":"User is looking for smooth finish for steel sheets"}</update_memory>Noted. I’ve added “smooth surface finish” as a requirement. Now, regarding packaging, would you like to specify protective coatings or moisture-resistant materials for transportation and storage?',
  'Yes, please include that the sheets should be wrapped in moisture-resistant materials.': `Understood. I’ve added “packaged with moisture-resistant materials” to the requirements. Here’s an updated summary of your request:
  
  - Material: Cold-rolled steel sheets
  - Grade: CR4
  - Thickness: 2mm
  - Quantity: 500 tons
  - Certification: ISO 9001
  - Preferred Location: Within the US
  - Delivery Timeline: Within 45 days
  - Payment Terms: Net 30 days
  - Shipping Terms: FOB
  - Surface Finish: Smooth
  - Packaging: Wrapped in moisture-resistant materials

  Is everything correct?`,
  'Yes, that looks perfect.': `Great! I'll update the request with these new requirements and continue searching for the best suppliers. You'll receive updates as we make progress and can consult the details [here](${VITE_BASE_UI_URL}/dashboard/members/supplier-demand-status#pipeline)`,
};

const demoOverallStatus: Record<string, string> = {
  'Overall Status': `
  ## Overview
  
  ### Cold-Rolled Steel Sheets:
  Current Status: We are actively searching for the best suppliers that meet your specific requirements. Several potential matches have been identified, and we are in the process of evaluating their capabilities and certifications to ensure they align with your needs.
  ### Copper Tubing:
  Current Status: Suppliers who match your criteria are being interviewed. We are assessing their ability to meet your demand specifications. We will update you soon with the shortlisted candidates.
  ### Acquisitions Legal Support
  Current Status: A meeting has been scheduled with Carter & Bowen Legal Advisors on September 1st. `,
};

const demoHealthInsuranceDemandStatus: Record<string, string> = {
  Status:
    '<status>{"title": "Cold-Rolled Steel Sheets Procurement", "subtitle":"Sourcing high-quality, ISO 9001 certified cold-rolled steel sheets for manufacturing.", "description":"This request involves finding a reliable supplier for 500 tons of cold-rolled steel sheets, specifically grade CR4 with a thickness of 2mm. The steel sheets are required for a manufacturing process, and the supplier must meet strict quality standards, including ISO 9001 certification. The preferred suppliers should be based within the US to ensure timely delivery, and the transaction will follow FOB shipping terms with net 30-day payment terms. The delivery timeline is critical, with a requirement to receive the steel sheets within 45 days.","requirements":"Material: Cold-rolled steel sheets, Grade: CR4, Thickness: 2mm, Quantity: 500 tons, Certification: ISO 9001, Preferred Location: Within the US, Delivery Timeline: Within 45 days, Payment Terms: Net 30 days, Shipping Terms: FOB"}</status>',
};

export const demos: Record<string, Record<string, string>> = {
  '1': demo1,
  SELLER_DEMO_THREAD_ID: demoSeller,
  HEALTH_INSURANCE_DEMO_THREAD_ID: demoHealthInsurance,
  OVERALL_STATUS_DEMO_THREAD_ID: demoOverallStatus,
  HEALTH_INSURANCE_SELLER_DEMO_THREAD_ID: demoHealthInsuranceDemandStatus,
};
