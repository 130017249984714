import axios, { type AxiosInstance } from 'axios';
import { STORAGE_KEY } from 'src/contexts/auth/auth-provider';
import { User } from 'src/types/user';
import { decode } from 'src/utils/jwt';
import { paths } from 'src/paths';

class AuthAxios {
  protected axiosInstance: AxiosInstance;

  constructor(params: { baseUrl: string }) {
    this.axiosInstance = axios.create({
      baseURL: `${params.baseUrl}/v1`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.injectHeaders(this.axiosInstance);
  }

  private checkExpiredToken(token: string) {
    const user = decode(token) as User;
    const currentTime = Math.floor(Date.now() / 1000); // To seconds
    if (currentTime > user.exp) {
      console.log('Token expired. Logging out.');
      sessionStorage.removeItem(STORAGE_KEY);
      window.location.href = paths.auth.login;
    }
  }

  private injectHeaders(axios: AxiosInstance) {
    axios.interceptors.request.use(
      (config) => {
        const token = sessionStorage.getItem(STORAGE_KEY);
        if (token) {
          this.checkExpiredToken(token);
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

export default AuthAxios;
