import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const { isAuthenticated, signInWithCognitoCode } = useAuth();
  const [checked, setChecked] = useState<boolean>(false);
  // TODO Set loading UX using isAuthenticating
  const [_isAuthenticating, setAuthenticating] = useState<boolean>(false);
  const location = useLocation();

  const check = useCallback(async () => {
    if (!isAuthenticated) {
      // Check if there's a code from cognito to authenticate
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      if (code) {
        try {
          setAuthenticating(true);
          await signInWithCognitoCode(code);
          setChecked(true);
          return;
        } catch (error) {
          console.log(error);
          throw error;
        } finally {
          setAuthenticating(false);
        }
      }

      const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString();
      const href = paths.auth.login + `?${searchParams}`;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, location.search, router, signInWithCognitoCode]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};
