import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/guards/guest-guard';

const SignUpPage = lazy(() => import('src/pages/auth/register'));
const VerificationPage = lazy(() => import('src/pages/auth/verification'));

export const newChamberRoutes: RouteObject[] = [
  {
    path: 'new-chamber',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      // {
      //   path: '/',
      //   element: <VerificationPage />,
      // },
      {
        path: ':signupKey',
        element: <SignUpPage />,
      },
    ],
  },
];
