import { createContext, useReducer, ReactNode, Dispatch, FC } from 'react';

interface DrawerState {
  open: boolean;
  drawerComponent: ReactNode;
  drawerWidth: number;
}

type DrawerAction =
  | { type: 'SET_DRAWER'; payload: ReactNode }
  | { type: 'SET_DRAWER_OPEN'; payload: boolean }
  | { type: 'SET_DRAWER_WIDTH'; payload: number };

const initialState: DrawerState = {
  open: true,
  drawerComponent: null,
  drawerWidth: 0,
};

// Reducer function to manage state
const drawerReducer = (state: DrawerState, action: DrawerAction): DrawerState => {
  switch (action.type) {
    case 'SET_DRAWER':
      return { ...state, drawerComponent: action.payload };
    case 'SET_DRAWER_OPEN':
      return { ...state, open: action.payload };
    case 'SET_DRAWER_WIDTH':
      return { ...state, drawerWidth: action.payload };
    default:
      return state;
  }
};

// Create Context for Drawer
export const DrawerContext = createContext<
  { state: DrawerState; dispatch: Dispatch<DrawerAction> } | undefined
>(undefined);

interface DrawerProviderProps {
  children: ReactNode;
}

export const DrawerProvider: FC<DrawerProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(drawerReducer, initialState);

  return <DrawerContext.Provider value={{ state, dispatch }}>{children}</DrawerContext.Provider>;
};
