import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Attendee, MeetingCommodity } from 'src/types/event-planner';
import { Commodity, DashboardEvent, Meeting, SMBBusiness } from 'src/types/organization';

type SetDashboardAction = PayloadAction<{ dashboard: DashboardEvent; attendee: Attendee }>;
type SetCalendarAction = PayloadAction<Meeting[]>;
type SetCommoditiesCatalogAction = PayloadAction<Commodity[]>;
type SetEnterpriseCommoditiesAction = PayloadAction<MeetingCommodity[]>;
type SetAgendaTimeAction = PayloadAction<{ start?: string; end?: string }>;
type IsUserEnrolledAction = PayloadAction<boolean>;
type SetSMBDetailsAction = PayloadAction<SMBBusiness>;
type SetEnterpriseAttendeeAction = PayloadAction<Attendee | null>;
type IsLoadingEnterpriseAttendeeAction = PayloadAction<boolean>;

interface EnterpriseEventState {
  dashboard: DashboardEvent;
  commoditiesCatalog: Commodity[];
  calendar: Meeting[];
  startTimeAgenda: string;
  endTimeAgenda: string;
  isUserEnrrolled: boolean;
  smbDetails: SMBBusiness | null;
  enterpriseAttendee: Attendee | null;
  isLoadingEnterpriseAttendee: boolean;
}

const initialState: EnterpriseEventState = {
  dashboard: {
    title: '',
    description: '',
    time_zone: '',
    location: '',
    payment_required: false,
    payment_info: '',
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    org_id: '',
    meeting_blocks: [],
  },

  commoditiesCatalog: [],
  calendar: [],
  startTimeAgenda: '2022-04-17T09:00',
  endTimeAgenda: '2022-04-17T17:00',
  isUserEnrrolled: false,
  smbDetails: null,
  enterpriseAttendee: null,
  isLoadingEnterpriseAttendee: false,
};

const reducers = {
  setDashboard(state: EnterpriseEventState, action: SetDashboardAction): void {
    state.dashboard = action.payload.dashboard;

    // attendee is undefined before joining the event
    if (action.payload.attendee) {
      const meeting_blocks = action.payload.attendee.meeting_blocks;
      if (meeting_blocks && meeting_blocks[0]) {
        const timeStringStart = meeting_blocks[0].start_time;
        const timeStringEnd = meeting_blocks[0].end_time;

        state.startTimeAgenda = `${meeting_blocks[0].start_date}T${timeStringStart}`;
        state.endTimeAgenda = `${meeting_blocks[0].end_date}T${timeStringEnd}`;
      }
    }
  },
  setCommoditiesCatalog(state: EnterpriseEventState, action: SetCommoditiesCatalogAction): void {
    state.commoditiesCatalog = action.payload;
  },
  isLoadingEnterpriseAttendee(
    state: EnterpriseEventState,
    action: IsLoadingEnterpriseAttendeeAction
  ): void {
    state.isLoadingEnterpriseAttendee = action.payload;
  },
  setEnterpriseCommodities(
    state: EnterpriseEventState,
    action: SetEnterpriseCommoditiesAction
  ): void {
    if (state.enterpriseAttendee?.commodities) {
      state.enterpriseAttendee.commodities = action.payload;
    } else {
      state.enterpriseAttendee = {
        ...state.enterpriseAttendee,
        commodities: action.payload,
      } as Attendee;
    }
  },
  setUserEnroll(state: EnterpriseEventState, action: IsUserEnrolledAction): void {
    state.isUserEnrrolled = action.payload;
  },
  setSMBDetails(state: EnterpriseEventState, action: SetSMBDetailsAction): void {
    state.smbDetails = action.payload;
  },
  setAgendaTime(state: EnterpriseEventState, action: SetAgendaTimeAction): void {
    if (action.payload.start) {
      state.startTimeAgenda = action.payload.start;
    }

    if (action.payload.end) {
      state.endTimeAgenda = action.payload.end;
    }
  },
  setCalendar(state: EnterpriseEventState, action: SetCalendarAction): void {
    state.calendar = action.payload;
  },
  setEnterpriseAttendee(state: EnterpriseEventState, action: SetEnterpriseAttendeeAction): void {
    state.enterpriseAttendee = action.payload;
  },
};

export const slice = createSlice({
  name: 'enterpriseEventPlanner',
  initialState,
  reducers,
});

export const { reducer } = slice;
