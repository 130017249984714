import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CHAT_AI_AUTHOR_KEY, LOADER_ICON } from 'src/api/chat/data';
import { ChatThread, Message } from 'src/types/chat';
import { removeFromArray } from 'src/utils/array';
import { objFromArray } from 'src/utils/obj-from-array';

type SetPromptTextAction = PayloadAction<string>;

type SetAIResponseAction = PayloadAction<string | null>;

type SetPlatformAction = PayloadAction<PlatformAction>;

type AddMessageAction = PayloadAction<{ message: Message; threadId: string }>;

type GetThreadsAction = PayloadAction<ChatThread[]>;

type GetThreadAction = PayloadAction<ChatThread | null>;

type LoadThreadMessagesAction = PayloadAction<{ threadId: string; messages: Message[] }>;

type SetCurrentThreadAction = PayloadAction<string | undefined>;

type PlatformActionType =
  | 'first_time_uploading_business'
  | 'business_uploaded'
  | 'business_upload_completed';

type PromptSubmission = 'test';

interface PlatformAction {
  action: PlatformActionType;
}

interface State {
  prompt: string;
  disabledPrompt: boolean;
  response: string | null;
  currentThreadId?: string;
  threads: {
    byId: Record<string, ChatThread>;
    allIds: string[];
  };
}

const initialState: State = {
  prompt: '',
  disabledPrompt: false,
  response: null,
  currentThreadId: undefined,
  threads: {
    allIds: [],
    byId: {},
  },
};

const firstTimeUploadingBusinessResponse = `**Get started by adding your first set of members**
1. Download the template
2. Submit the file
3. Wait for the enrichment process to be completed`;

const reducers = {
  resetPrompt(state: State): void {
    state.prompt = initialState.prompt;
    state.disabledPrompt = initialState.disabledPrompt;
    state.response = initialState.response;
  },
  setPromptText(state: State, action: SetPromptTextAction): void {
    state.prompt = action.payload;
  },
  submitPrompt(state: State): void {
    switch (state.prompt as PromptSubmission) {
      case 'test':
        state.response = firstTimeUploadingBusinessResponse;
        state.disabledPrompt = false;
        break;
      default:
        state.response = 'Sorry I did not understand';
        state.disabledPrompt = false;
    }
  },
  setAIResponse(state: State, action: SetAIResponseAction): void {
    state.response = action.payload;
  },
  informAI(state: State, action: SetPlatformAction): void {
    // Make decisions based on the action it was taken on the platform
    switch (action.payload.action) {
      case 'first_time_uploading_business':
        state.prompt = 'What is next?';
        state.disabledPrompt = true;
        state.response = firstTimeUploadingBusinessResponse;
        break;
      case 'business_uploaded':
        state.prompt = '';
        state.disabledPrompt = true;
        state.response =
          'Thank you for uploading your data, we are saving it. As soon as we have it ready, I will redirect you to the platform.';
        break;
      case 'business_upload_completed':
        state.prompt = '';
        state.disabledPrompt = true;
        state.response =
          'We have saved the information you provided. I will redirect you to the platform now!';
        break;
      default:
        throw new Error(
          `Platform Action not supported. Following action was received: ${action.payload.action}`
        );
    }
  },
  getThreads(state: State, action: GetThreadsAction): void {
    const threads = action.payload;

    state.threads.byId = objFromArray(threads);
    state.threads.allIds = Object.keys(state.threads.byId);
  },
  getThread(state: State, action: GetThreadAction): void {
    const thread = action.payload;

    if (thread) {
      state.threads.byId[thread.id!] = thread;

      if (!state.threads.allIds.includes(thread.id!)) {
        state.threads.allIds.unshift(thread.id!);
      }
    }
  },
  addMessage(state: State, action: AddMessageAction): void {
    const { threadId, message } = action.payload;
    const thread = state.threads.byId[threadId];
    const lastIndex = thread.messages.length - 1;

    // Remove loader message if any
    if (thread.messages[lastIndex]?.id === LOADER_ICON) {
      thread.messages = removeFromArray(lastIndex, thread.messages);
    }

    if (thread) {
      if (
        message.eventType === 'chunk_stream_response' ||
        message.eventType === 'end_stream_response'
      ) {
        thread.messages[lastIndex].body += message.body;
      } else {
        thread.messages.push(message);
      }
    }

    // Add loader message
    if (message.authorId !== CHAT_AI_AUTHOR_KEY) {
      thread.messages.push({
        attachments: [],
        authorId: CHAT_AI_AUTHOR_KEY,
        body: 'Gathering info...',
        contentType: 'loading',
        createdAt: new Date().getTime(),
        id: LOADER_ICON,
        read: false,
      });
    }

    state.response = thread.messages[thread.messages.length - 1].body;
  },
  loadThreadMessages(state: State, action: LoadThreadMessagesAction) {
    state.threads.byId[action.payload.threadId] = {
      ...state.threads.byId[action.payload.threadId],
      messages: action.payload.messages,
    };
  },
  setCurrentThread(state: State, action: SetCurrentThreadAction): void {
    state.currentThreadId = action.payload;
  },
};

export const slice = createSlice({
  name: 'ai-prompt',
  initialState,
  reducers,
});

export const { reducer } = slice;
