import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/guards/guest-guard';

const SignUpSupplierPage = lazy(() => import('src/pages/auth/smb-signup-supplier'));

export const newSupplierRoutes: RouteObject[] = [
  {
    path: 'new-supplier',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      // {
      //   path: '/',
      //   element: <VerificationPage />,
      // },
      {
        path: ':chamber',
        element: <SignUpSupplierPage />,
      },
    ],
  },
];
