import { type FC, type ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import MiniDrawer from './mini-drawer';

const HorizontalLayoutRoot = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  width: '100%',
});

interface HorizontalLayoutProps {
  children?: ReactNode;
}

export const HorizontalLayout: FC<HorizontalLayoutProps> = (props) => {
  const { children } = props;

  return (
    <>
      <HorizontalLayoutRoot>
        <MiniDrawer>{children}</MiniDrawer>
      </HorizontalLayoutRoot>
    </>
  );
};
