import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Notification } from 'src/types/notifications';
import { removeFromArray } from 'src/utils/array';

type LoadNotificationsAction = PayloadAction<Notification[]>;
type RemoveNotificationAction = PayloadAction<string>;

interface State {
  notifications: Notification[];
}

const initialState: State = {
  notifications: [],
};

const reducers = {
  loadNotifications(state: State, action: LoadNotificationsAction): void {
    state.notifications = action.payload;
  },
  removeNotification(state: State, action: RemoveNotificationAction): void {
    console.log('id to remove', action.payload);
    const index = state.notifications.findIndex(
      (notification) => notification.notification_id === action.payload
    );
    console.log('index', index);
    if (index) {
      state.notifications = removeFromArray(index, state.notifications);
    }
  },
};

export const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers,
});

export const { reducer } = slice;
