import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CHAT_AI_AUTHOR_KEY, LOADER_ICON } from 'src/api/chat/data';
import { VITE_BASE_UI_URL } from 'src/config';
import { paths } from 'src/paths';
import { Message, ChatThread, CardResult, TileCreation } from 'src/types/chat';
import { removeFromArray } from 'src/utils/array';
import { createResourceId } from 'src/utils/create-resource-id';
import { objFromArray } from 'src/utils/obj-from-array';

type GetThreadsAction = PayloadAction<ChatThread[]>;

type GetThreadAction = PayloadAction<ChatThread | null>;

type LoadThreadMessagesAction = PayloadAction<{ threadId: string; messages: Message[] }>;

type MarkThreadAsSeenAction = PayloadAction<string>;

type SetCurrentThreadAction = PayloadAction<string | undefined>;

type AddMessageAction = PayloadAction<{ message: Message; threadId: string }>;

type DemoModeAction = PayloadAction<{ isDemo: boolean; key: string }>;

type ConversationIndexAction = PayloadAction<number>;

type PaginationAction = PayloadAction<{ page: number; isEndOfPages: boolean }>;

type DeleteThreadAction = PayloadAction<{ threadId: string }>;

interface OrganizationChatState {
  currentThreadId?: string;
  threads: {
    byId: Record<string, ChatThread>;
    allIds: string[];
  };
  demo: {
    isDemoMode: boolean;
    key: string;
  };
  conversationIndex: number;
  llmBackgroundActions: Message[];
  pagination: {
    page: number;
    isEndOfPages: boolean;
  };
}

const initialState: OrganizationChatState = {
  currentThreadId: undefined,
  threads: {
    allIds: [],
    byId: {},
  },
  demo: {
    isDemoMode: false,
    key: '',
  },
  conversationIndex: 0,
  llmBackgroundActions: [],
  pagination: {
    page: 1,
    isEndOfPages: false,
  },
};

const reducers = {
  getThreads(state: OrganizationChatState, action: GetThreadsAction): void {
    const threads = action.payload;

    state.threads.byId = objFromArray(threads);
    state.threads.allIds = Object.keys(state.threads.byId);
    state.pagination = initialState.pagination;
  },
  getThread(state: OrganizationChatState, action: GetThreadAction): void {
    const thread = action.payload;
    state.pagination = initialState.pagination;

    if (thread) {
      state.threads.byId[thread.id!] = thread;

      if (!state.threads.allIds.includes(thread.id!)) {
        state.threads.allIds.unshift(thread.id!);
      }
    }
  },
  loadThreadMessages(state: OrganizationChatState, action: LoadThreadMessagesAction) {
    state.threads.byId[action.payload.threadId] = {
      ...state.threads.byId[action.payload.threadId],
      messages: action.payload.messages,
    };
  },
  markThreadAsSeen(state: OrganizationChatState, action: MarkThreadAsSeenAction): void {
    const threadId = action.payload;
    const thread = state.threads.byId[threadId];

    if (thread) {
      thread.unreadCount = 0;
    }
  },
  setCurrentThread(state: OrganizationChatState, action: SetCurrentThreadAction): void {
    state.currentThreadId = action.payload;
    state.pagination = initialState.pagination;

    // Mark messages from the thread as read
    if (state.currentThreadId) {
      state.threads.byId[state.currentThreadId].messages = state.threads.byId[
        state.currentThreadId
      ].messages.map((message) => {
        return {
          ...message,
          read: true,
        };
      });
    }
  },
  deleteThread(state: OrganizationChatState, action: DeleteThreadAction): void {
    const newIds: Record<string, ChatThread> = {};
    Object.keys(state.threads.byId).forEach((key: string) => {
      if (key != action.payload.threadId) {
        newIds[key] = state.threads.byId[key];
      }
    });
    state.threads.byId = newIds;
    const index = state.threads.allIds.findIndex((id) => id === action.payload.threadId);
    state.threads.allIds = removeFromArray(index, state.threads.allIds);
  },
  addMessage(state: OrganizationChatState, action: AddMessageAction): void {
    const { threadId, message } = action.payload;
    const thread = state.threads.byId[threadId];
    const isDemoMode = state.demo.isDemoMode;
    const lastIndex = thread.messages.length - 1;

    // Remove loader message if any
    if (thread.messages[lastIndex]?.id === LOADER_ICON) {
      thread.messages = removeFromArray(lastIndex, thread.messages);
    }

    if (thread) {

      if (
        message.eventType === 'chunk_stream_response' ||
        message.eventType === 'complementary_response' ||
        message.eventType === 'end_stream_response'
      ) {
        thread.messages[lastIndex].body += message.body;
      } else if (message.eventType === 'intermediate_response') {
        state.llmBackgroundActions.push(message);
        // Remove loader if any
        if (thread.messages[lastIndex]?.id === LOADER_ICON) {
          thread.messages = removeFromArray(lastIndex, thread.messages);
        }
      } else {
        if (
          message.eventType === 'begin_stream_response' &&
          thread.messages?.[lastIndex]?.authorId === CHAT_AI_AUTHOR_KEY
        ) {
          // Append message (we received another begin stream before user send a message)
          thread.messages[lastIndex].body += message.body;
        } else {
          thread.messages.push(message);
        }
      }

      if (message.eventType !== 'intermediate_response') {
        state.llmBackgroundActions = [];
      }
    }

    // Add loader message
    if (message.authorId !== CHAT_AI_AUTHOR_KEY) {
      thread.messages.push({
        attachments: [],
        authorId: CHAT_AI_AUTHOR_KEY,
        body: 'Gathering info...',
        contentType: 'loading',
        createdAt: new Date().getTime(),
        id: LOADER_ICON,
        read: false,
      });
    }

    // Auto-respond to the thread
    if (isDemoMode) {
      // Remove loader
      if (thread.messages[thread.messages.length - 1]?.id === LOADER_ICON) {
        thread.messages = removeFromArray(thread.messages.length - 1, thread.messages);
      }

      // Respond back
      const nextResponse =
        demos[state.demo.key][thread.messages[thread.messages.length - 1].body] ??
        "I'm sorry, I didn't understand";
      const { cards, cleanedString: nextString } = extractCardsAndCleanString(nextResponse);
      const { jsonData, clearedString } = extractJSONAndClearActionTag(nextString);

      thread.messages.push({
        attachments: [],
        authorId: CHAT_AI_AUTHOR_KEY,
        body: jsonData ? JSON.stringify(jsonData) : clearedString,
        contentType: jsonData ? 'json' : 'text',
        createdAt: new Date().getTime(),
        id: createResourceId(),
        cardResults: cards,
        read: false,
        eventType: action.payload.message.eventType,
      });
      state.conversationIndex = state.conversationIndex + 1;
    }
  },
  setDemoMode(state: OrganizationChatState, action: DemoModeAction): void {
    state.demo.isDemoMode = action.payload.isDemo;
    state.demo.key = action.payload.key;
  },
  setConversationIndex(state: OrganizationChatState, action: ConversationIndexAction): void {
    state.conversationIndex = action.payload;
  },
  setPagination(state: OrganizationChatState, action: PaginationAction): void {
    state.pagination.page = action.payload.page;
    state.pagination.isEndOfPages = action.payload.isEndOfPages;
  },
};

function extractCardsAndCleanString(inputString: string): {
  cards: CardResult[];
  cleanedString: string;
} {
  // Regular expression to find all <card>...</card> contents
  const cardRegex = /<card>(.*?)<\/card>/gs;
  const cards: CardResult[] = [];

  let cardMatch;
  // Iterate over all <card> matches and extract information
  while ((cardMatch = cardRegex.exec(inputString)) !== null) {
    const cardContent = cardMatch[1];
    const titleMatch = cardContent.match(/<title>(.*?)<\/title>/s);
    const contentMatch = cardContent.match(/<content>(.*?)<\/content>/s);

    if (titleMatch && contentMatch) {
      cards.push({
        title: titleMatch[1],
        content: contentMatch[1],
      });
    }
  }

  // Remove all <card>...</card> elements from the original string
  const cleanedString = inputString.replace(cardRegex, '');

  return { cards, cleanedString };
}

interface ExtractionResult {
  jsonData: Record<string, any> | null;
  clearedString: string;
}

function extractJSONAndClearActionTag(inputString: string): ExtractionResult {
  const regex = /<action>(.*?)<\/action>/;
  const match = inputString.match(regex);

  if (match && match[1]) {
    const clearedString = inputString.replace(regex, '');
    try {
      const json: Record<string, any> = JSON.parse(match[1]);
      return { jsonData: json, clearedString };
    } catch (e) {
      console.error('Extracted string is not valid JSON:', match[1]);
      return { jsonData: null, clearedString };
    }
  } else {
    console.log('No <action> tags found or no content inside <action> tags.');
    return { jsonData: null, clearedString: inputString };
  }
}

export const slice = createSlice({
  name: 'organizationChat',
  initialState,
  reducers,
});

export const { reducer } = slice;

export const demoSctiptEnterprise: Record<string, string> = {
  'start demo 1': `Hi Emily, Welcome back!
  
  I've identified that 8 suppliers are now at a risk level over 90%. This is primarily due to increased geopolitical tensions.
  
  Would you like to visit the [suppliers page](${VITE_BASE_UI_URL}/dashboard/enterprise/suppliers) to begin mitigation efforts?`,
  'What are the 8 suppliers at risk?': `Here are the 8 suppliers at risk:
  
  | Supplier              | Location            |
  |-----------------------|:---------------------:|
  | GlobalTech Partners   | SHENZHEN, CHINA     |
  | EuroSupply Solutions  | BERLIN, GERMANY     |
  | ChinaStar Enterprises | SHANGHAI, CHINA     |
  | ABC Manufacturing     | NEW YORK, USA       |
  | XYZ Supplies          | LONDON, UK          |
  | Dell Technologies     | ROUND ROCK, TEXAS   |
  | IBM                   | ARMONK, NEW YORK    |
  | Microsoft             | REDMOND, WASHINGTON |

  `,
  'I am also concerned with what is going on in the middle east. Please create a tile for any suppliers which may be impacted': `Sure. How often would you like it to be updated?`,
  Daily: `Done. Your tile can be found [here](${VITE_BASE_UI_URL}/dashboard/chambers?demo=enterprise)`,
  // 'Show me the Downloadable content': `<action>{"type": "downloadable"}</action>`,
};
export const demoSctipt: Record<string, string> = {
  'start demo 2': 'Ok!',
  'Who is ACME Engineering?': `ACME Engineering is a dynamic and innovative engineering firm based in Silicon Valley, specializing in cutting-edge robotics and artificial intelligence solutions. Founded in 2010, the company has rapidly become a leader in developing advanced automation systems for a variety of industries, including manufacturing, healthcare, and automotive. ACME Engineering is committed to pushing the boundaries of technology to create efficient, sustainable, and intelligent systems that enhance productivity and drive progress. With a team of world-class engineers and a state-of-the-art research facility, ACME is dedicated to delivering groundbreaking solutions that anticipate the future needs of a rapidly evolving global market.<card><title>ACME Engineering</title><content>3505 Thomas Drive, Palo Alto, CA 94304, USA</content></card>`,
  'Show me a list of my members': `There are 1,223 members, would you like to see a more specific list?`,
  'No, first please show me a breakdown by sectors': `Here is a breakdown by sector:
  | Sector                                        | Number of Businesses |
|:-----------------------------------------------|:----------------------:|
| Computers and Electronics                     | 249                   |
| Construction                                  | 234                   |
| Electronics                                   | 179                   |
| Renewable Energy Semiconductor Manufacturing  | 149                   |
| Banking                                       | 124                   |
| Aerospace                                     | 100                   |
| Semiconductors                                | 75                    |
| Veterinary Services                           | 60                    |
| Financial Services                            | 50                    |
| Industrial machinery                          | 43                    |
  `,
  'Show me all manufacturing companies in East Midlands with more than 100 employees': `There you go:
  | Company                           | Address                                                                         |
  |:----------------------------------|:--------------------------------------------------------------------------------|
  | WHITFORD LIMITED                  | 11 Stuart Road Manor Park Runcorn WA7 1TH                                           |
  | A L H SYSTEMS LIMITED             | 1 Kingdom Avenue Northacre Industrial Park Westbury BA13 4WE                        |
  | PARISOL CHEMICALS LIMITED         | 1 Bond Street Colne BB8 9DG                                                         |
  | PRECISION PAINTS (ESSEX) LTD      | Unit 13 Orwell Court Hurricane Way Wickford SS11 8YJ                                |
  | WATERMAN DISPERSIONS LIMITED      | Unit 11 Two Bridge Industrial Estate Newhey Rochdale OL16 3SR                       |
  | INVER UK LIMITED                  | 1 Bridgewater Place Water Lane Leeds LS11 5QR                                       |
  | NUTSHELL NATURAL PAINTS LIMITED   | 32 Fairmead Road Saltash PL12 4JG                                                   |
  | SPECIAL PAINT SOLUTIONS LTD       | 33 Halton Place Cleethorpes DN35 9BT                                                |
  | U-POL MEZZANINE LIMITED           | U-Pol Tech Centre Denington Road Denington Industrial Estate Wellingborough NN8 2QH |
  | LYSON LIMITED                     | Nazdar House Battersea Road Heaton Mersey Industrial Estate Stockport SK4 3EA       |  
  `,
  'Great, please give me an xls': `Here is a [link](https://lp-platform-templates.s3.amazonaws.com/members_details.xlsx) where you can download the file.`,
  'Prepare an email that invites all new members of 2024 to an event called Welcome': `**Subject:** Welcome Aboard: Join Us for Our Exclusive 2024 Welcome Event!

  **Body:**
  
  Dear [Member Name],
  
  We are thrilled to welcome you to our community! To kick off your journey with us in 2024, we're excited to invite you to our inaugural event, aptly named Welcome. This event is designed exclusively for all our new members like you, offering a perfect opportunity to meet, mingle, and embark on this exciting new chapter together.
  
  Event Details:
  
  Date: [Insert Date]
  
  Time: [Insert Time]
  
  Venue: [Insert Venue Location]
  
  Dress Code: Come as you are!
  
  Welcome is more than just an event; it's the beginning of something special. It's where you'll meet fellow members, learn more about what we have in store for you, and most importantly, have fun!
  
  We believe that every member is a vital part of our community's fabric, and we can't wait to get to know you better. Whether you're looking to network, find friends, or just have a good time, this event is the perfect starting point.
  
  Please RSVP by [RSVP Deadline Date] to secure your spot. You can RSVP by replying to this email or clicking [Insert RSVP Link if applicable].
  
  Should you have any questions or require further information, please don't hesitate to reach out. We're here to make your experience as welcoming and enjoyable as possible.
  
  Looking forward to meeting you at Welcome!
  
  Warmest regards,
  
  [Your Name]
  
  [Your Title/Position]
  
  [Your Contact Information]`,
};

export const demoBusinessMatch: Record<string, string> = {
  'start demo 3': `Hi Anne,

  I have identified a new need for **EcoElectric Motors**, an enterprise with a large supply chain. **Would you like to know more about it?**`,
  'Sure!': `**EcoElectric Motors** is looking for a reliable supplier of **high-quality electric vehicle charging cables**. This is a great opportunity for you, as you have several businesses specializing in **manufacturing durable and efficient charging cables**.

  Would you like me to start a business matching process based on the needs of EcoElectric Motors?`,
  'Yes, please!': `Here’s a brief summary of what comes next:

  1. I will reach out to potential businesses that meet EcoElectric Motors' requirements.
  2. I will request any additional information needed from these businesses.
  3. Once I have gathered a list of potential matches, I will inform you so you can decide which ones are the best fit.
  4. You will then be able to organize an event between EcoElectric Motors and the selected businesses to facilitate introductions and potentially build new relationships.
  5. Let me know if you agree with this, and we can continue.`,
  'Sounds good! Lets do it!':
    'Is there any specific date or timeframe you are looking for this event? This will help me to know the time we have to collect all the information and this could impact in the number of businesses I can gather',
  "Let's put a 2 months timeframe": `Great! [Here](${VITE_BASE_UI_URL}${paths.dashboard.chambers.businessMatching}) is a link where you can track the progress of this event.`,
};
export const demoBusinessEssentials: Record<string, string> = {
  'start demo 4': `Hello! I'm an AI assistant here to help you find the best supplier for your company's needs.`,
  "Thanks! I'm looking for a supplier who can provide high-quality steel sheets for manufacturing.": `Great! Could you please provide more details about the specific requirements? For example, the grade of steel, thickness, quantity needed, and any certification requirements.`,
  'Sure! We need 500 tons of cold-rolled steel sheets, grade CR4, with a thickness of 2mm. We require ISO 9001 certification and would prefer a supplier within the US.': `Understood. Do you have a preferred delivery timeline or any specific terms you'd like the supplier to meet, such as payment terms or delivery conditions?`,
  'We need the delivery within 45 days, and the payment terms should be net 30 days. FOB shipping terms would be ideal.': `Thank you for providing all the necessary information. Here’s a summary of your request:

  - Material: Cold-rolled steel sheets
  - Grade: CR4
  - Thickness: 2mm
  - Quantity: 500 tons
  - Certification: ISO 9001
  - Preferred Location: Within the US
  - Delivery Timeline: Within 45 days
  - Payment Terms: Net 30 days
  - Shipping Terms: FOB
  
  Is everything correct?`,
  'Yes, that looks perfect.': ` Excellent! I'll now start searching for suppliers that match your requirements. Please register [here](${VITE_BASE_UI_URL}/auth/login?conversation_id=ua2habd33) so you can keep track of the process and receive updates directly.`,
};

const demoSeller: Record<string, string> = {
  'start demo 5': `Hello! We have some exciting news for you. A company is actively searching for cold-rolled steel sheets, and you might be a good match for this opportunity. We’re aware that you are ISO 9001 certified, located in the US, and have experience working with steel sheets. Would you be willing to answer a few more questions to ensure a perfect fit with the client’s criteria?`,

  'Sure': "Wonderful! Let’s get started, first, can you confirm that you can supply 500 tons of cold-rolled steel sheets, grade CR4, with a thickness of 2mm?",
  'Yes, we can supply that quantity and grade.': `Excellent. The client requires a smooth surface finish on the steel sheets. Is this something your production process can accommodate?`,
  'Yes, we can provide a smooth finish on our steel sheets.': `Great! The client has also requested that the sheets be packaged with moisture-resistant materials. Can you meet this packaging requirement?`,
  'Absolutely, we use moisture-resistant packaging for all our shipments.': "Fantastic! Lastly, the client needs the delivery within 45 days. Is this timeline feasible for your production and shipping schedules?",
  "Yes, we can deliver within that time frame.":`That’s wonderful to hear. Based on your answers, it sounds like you’re a strong candidate for this opportunity. We’ll proceed with matching you to the client’s request, please register [here](${VITE_BASE_UI_URL}/autg/login) so you can keep track of the process and receive updates directly.`,
};

const demoLegalServices: Record<string, string> = {
  'start demo 6': `Hello! I'm an AI assistant here to help you find the best supplier for your company's needs`,
  "Thanks! I'm looking for someone to help with drafting, reviewing, and negotiating contracts for our upcoming projects": `Got it! Could you provide more details on the type of contracts? For example, are they related to vendor agreements, partnership deals, or something else?`,
  'Mainly vendor agreements for sourcing raw materials, and we need someone to negotiate the terms with our suppliers':
    'Understood. Are there any specific industries or regions the contracts are related to? This can help us match you with the right legal service provider',
  "Yes, they're related to steel sheet supply and some equipment leasing, primarily within the US":
    "Great! Just to clarify, are there any certifications or legal expertise you're looking for, such as experience in commercial law or specific contract law certifications?",
  'Yes, experience in commercial law and a strong background in contract negotiation would be ideal.': `Perfect! Here's a summary of your request:
  - Service: Drafting, reviewing, and negotiating contracts
  - Contract Type: Vendor agreements (steel sheet supply and equipment leasing)
  - Region: US-based contracts
  - Expertise: Commercial law with contract negotiation experience
  
  I'll start searching for legal service providers that match your needs. Please register here to keep track of the process and receive updates.`,
  'Sounds good, thank you!': "You're welcome! I'll update you once I find suitable providers.",
};

export const demos: Record<string, Record<string, string>> = {
  '1': demoSctiptEnterprise,
  '2': demoSctipt,
  '3': demoBusinessMatch,
  '4': demoBusinessEssentials,
  '5': demoSeller,
  '6': demoLegalServices,
};
