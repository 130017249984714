import { lazy } from 'react';
import { type RouteObject } from 'react-router';
import { authRoutes } from './auth';
import { newChamberRoutes } from './new-chamber';
import { bizRoutes } from './biz';
import { newEnterpriseRoutes } from './new-enterprise';
import { newSupplierRoutes } from './new-supplier';
import { V2ChambersRoutes } from './v2-chambers';
import { welcomeChamberRoutes } from './welcome-chamber';
import { V2MembersRoutes } from './v2-members';
import { welcomeBusinessRoutes } from './welcome-business';

const Error401Page = lazy(() => import('src/pages/401'));
const Error404Page = lazy(() => import('src/pages/404'));
const Error500Page = lazy(() => import('src/pages/500'));

const ContactPage = lazy(() => import('src/pages/contact'));
const WelcomePage = lazy(() => import('src/pages/welcome'));
const VerificationPage = lazy(() => import('src/pages/auth/verification'));
const SorryPage = lazy(() => import('src/pages/auth/sorry'));
const NewPasswordPage = lazy(() => import('src/pages/auth/temp-password'));
const GoogleLoadingPage = lazy(() => import('src/pages/auth/google-load'));
const ClaimProfileSignupPage = lazy(() => import('src/pages/auth/smb-signup-profile'));
const DeprecatedMemberChatPage = lazy(() => import('src/pages/deprecated-member-chat'));
const MembersChatPage = lazy(() => import('src/pages/members-chat'));
const GoogleSMBPage = lazy(() => import('src/pages/auth/google-signup'));
const SignUpSellerPage = lazy(() => import('src/pages/auth/signup-seller'));
const RedirectPage = lazy(() => import('src/pages/redirect'));

export const routes: RouteObject[] = [
  ...newChamberRoutes,
  ...authRoutes,
  // ...dashboardRoutes,
  ...V2ChambersRoutes,
  ...V2MembersRoutes,
  ...bizRoutes,
  ...newEnterpriseRoutes,
  ...newSupplierRoutes,
  ...welcomeChamberRoutes,
  ...welcomeBusinessRoutes,
  {
    path: 'contact',
    element: <ContactPage />,
  },
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: 'welcome',
    element: <WelcomePage />,
  },
  {
    path: 'new-chamber-verification',
    element: <VerificationPage />,
  },
  {
    path: 'sorry',
    element: <SorryPage />,
  },
  {
    path: 'change-password',
    element: <NewPasswordPage />,
  },
  {
    path: 'google-login',
    element: <GoogleLoadingPage />,
  },
  {
    path: 'google-smb',
    element: <GoogleSMBPage />,
  },
  {
    path: 'signup',
    element: <ClaimProfileSignupPage />,
  },
  {
    path: 'signup-seller',
    element: <SignUpSellerPage />,
  },
  {
    path: 'member-chat',
    element: <DeprecatedMemberChatPage />,
  },
  {
    path: 'capture-need',
    element: <MembersChatPage />,
  },
  {
    path: '*',
    element: <RedirectPage />,
  },
];
