import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/guards/guest-guard';

const TempStorefrontPage = lazy(() => import('src/pages/temp-storefront'));

export const bizRoutes: RouteObject[] = [
  {
    path: 'biz',
    element: <Outlet />,
    children: [
      {
        path: ':id',
        element: <TempStorefrontPage />,
      },
    ],
  },
];
