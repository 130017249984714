import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type GetAction = PayloadAction<string>;
interface SomeState {
  someProperty: string;
}

const initialState: SomeState = {
  someProperty: '',
};

const reducers = {
  exampleReducer(state: SomeState, action: GetAction): void {
    state.someProperty = action.payload;
  },
};

export const slice = createSlice({
  name: 'mail',
  initialState,
  reducers,
});

export const { reducer } = slice;
