import axios, { Axios } from 'axios';
import { VITE_API_LEVELPLANE } from 'src/config';
import { STORAGE_KEY } from 'src/contexts/auth/auth-provider';

interface SendFeedbackParams {
  pathname: string;
  comment: string;
  email: string;
}

class FeedbackAPI {
  private instanceAxios: Axios;

  constructor() {
    this.instanceAxios = axios.create({
      baseURL: `${VITE_API_LEVELPLANE}/v1`,
    });
  }

  async sendFeedback(params: SendFeedbackParams): Promise<void> {
    const { pathname, comment, email } = params;
    try {
      const token = sessionStorage.getItem(STORAGE_KEY);
      await this.instanceAxios.post(
        '/feedback',
        { comment, email, url: pathname },
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        }
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export const feedbackAPI = new FeedbackAPI();
