import { FC, ReactNode, useEffect, useState } from "react"
import toast from "react-hot-toast";
import LoadingPage from "src/components/loading-page";
import { useAuth } from "src/hooks/use-auth";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/onboarding";

interface PreFetchProps {
  children: ReactNode
}

const PreFetch: FC<PreFetchProps> = (props) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (user?.smb_id) { 
          await Promise.all([
            dispatch(thunks.fetchInteractionStyle()),
            dispatch(thunks.fetchBusinessProfile(user.smb_id)),
            dispatch(thunks.fetchCompetitors(user.smb_id)),
          ]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        toast.error('There was an error fetching welcome data.');
      }
    };

    fetchInitialData();
  }, [dispatch, user?.smb_id]);

  if (isLoading) {
    return <LoadingPage theme="light" />;
  }

  return <>{props.children}</>
}

export default PreFetch;