import { VITE_API_LEVELPLANE } from 'src/config';
import AuthAxios from '../auth-axios';
import { Competitor } from 'src/types/competitor';
import { APIResultListFast } from 'src/types/api';

class CompetitorsAPI extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async getCompetitors(smbId: string): Promise<APIResultListFast<Competitor>> {
    try {
      const response = await this.axiosInstance.get(`/competitors/${smbId}`);
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        console.warn(`No competitors found for SMB ID: ${smbId}. Returning empty list.`);
        return { results: [], count: 0, page: 1 };
      }
      console.error(`Error getting competitors for SMB ID: ${smbId}`, error);
      throw error;
    }
  }

  async submitCompetitorsSearch(smbId: string): Promise<void> {
    try {
      await this.axiosInstance.post(`/competitors/${smbId}`);
    } catch (error) {
      console.error(`Error posting competitors for SMB ID: ${smbId}`, error);
      throw error;
    }
  }
}
export const competitorsAPI = new CompetitorsAPI();
