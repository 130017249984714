import { VITE_API_LEVELPLANE } from 'src/config';
import AuthAxios from '../auth-axios';
import { APIListNoCount } from 'src/types/api';
import { Notification } from 'src/types/notifications';

class NotificationsAPI extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async getNotifications(): Promise<APIListNoCount<Notification>> {
    try {
      const { data } = await this.axiosInstance.get<APIListNoCount<Notification>>(`/notifications`);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async changeStatus(id: string, status: Notification['status']) {
    try {
      const encodedId = encodeURIComponent(id);
      const response = await this.axiosInstance.patch(`/notifications/${encodedId}`, {
        status,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteNotification(id: string) {
    try {
      const response = await this.axiosInstance.delete(`/notifications/${id}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export const notificationsAPI = new NotificationsAPI();
