import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CHAT_AI_AUTHOR_KEY, LOADER_ICON } from 'src/api/chat/data';

import type { Contact, Message, Thread } from 'src/types/chat';
import { objFromArray } from 'src/utils/obj-from-array';

interface ChatState {
  contacts: {
    byId: Record<string, Contact>;
    allIds: string[];
  };
  currentThreadId?: string;
  threads: {
    byId: Record<string, Thread>;
    allIds: string[];
  };
  demo: {
    isDemoMode: boolean;
    version: string;
  };
  speech: {
    isSpeechMode: boolean;
    isSpeaking: boolean;
  };
  isWaitingForResponse: boolean;
}

type GetContactsAction = PayloadAction<Contact[]>;

type GetThreadsAction = PayloadAction<Thread[]>;

type GetThreadAction = PayloadAction<Thread | null>;

type MarkThreadAsSeenAction = PayloadAction<string>;

type SetCurrentThreadAction = PayloadAction<string | undefined>;

type AddMessageAction = PayloadAction<{ message: Message; threadId: string }>;

type RemoveLoaderIfAny = PayloadAction<{ threadId: string }>;

type DemoModeAction = PayloadAction<{ isDemoMode: boolean; version: string }>;

type RemoveMessages = PayloadAction<{ numberOfLastMessages: number }>;

type SpeechModeAction = PayloadAction<{ isSpeechMode: boolean }>;

type SpeakModeAction = PayloadAction<{ isSpeaking: boolean }>;

type AppendToLastMessageAction = PayloadAction<{
  threadId: string;
  body: string;
  contentType: Message['contentType'];
}>;

const initialState: ChatState = {
  contacts: {
    byId: {},
    allIds: [],
  },
  currentThreadId: undefined,
  threads: {
    byId: {},
    allIds: [],
  },
  demo: {
    isDemoMode: false,
    version: 'demo1',
  },
  speech: {
    isSpeechMode: false,
    isSpeaking: false,
  },
  isWaitingForResponse: false,
};

const reducers = {
  getContacts(state: ChatState, action: GetContactsAction): void {
    const contacts = action.payload;

    state.contacts.byId = objFromArray(contacts);
    state.contacts.allIds = Object.keys(state.contacts.byId);
  },
  getThreads(state: ChatState, action: GetThreadsAction): void {
    const threads = action.payload;

    state.threads.byId = objFromArray(threads);
    state.threads.allIds = Object.keys(state.threads.byId);
  },
  getThread(state: ChatState, action: GetThreadAction): void {
    const thread = action.payload;

    if (thread) {
      state.threads.byId[thread.id!] = thread;

      if (!state.threads.allIds.includes(thread.id!)) {
        state.threads.allIds.unshift(thread.id!);
      }
    }
  },
  markThreadAsSeen(state: ChatState, action: MarkThreadAsSeenAction): void {
    const threadId = action.payload;
    const thread = state.threads.byId[threadId];

    if (thread) {
      thread.unreadCount = 0;
    }
  },
  setCurrentThread(state: ChatState, action: SetCurrentThreadAction): void {
    state.currentThreadId = action.payload;
  },
  appendToLastMessage(state: ChatState, action: AppendToLastMessageAction) {
    const thread = state.threads.byId[action.payload.threadId];
    thread.messages[thread.messages.length - 1].body += action.payload.body;
    // If this message is already an email question, it should not be changed back to "text"
    if (thread.messages[thread.messages.length - 1].contentType !== 'email_question') {
      thread.messages[thread.messages.length - 1].contentType = action.payload.contentType;
    }
  },
  addMessage(state: ChatState, action: AddMessageAction): void {
    const { threadId, message } = action.payload;
    const thread = state.threads.byId[threadId];

    if (message.id === LOADER_ICON) {
      state.isWaitingForResponse = true;
    }

    if (thread) {
      thread.messages.push(message);
    }
  },
  removeMessages(state: ChatState, action: RemoveMessages) {
    const { numberOfLastMessages } = action.payload;
    state.threads.byId[state.currentThreadId ?? ''].messages.splice(-numberOfLastMessages);
  },
  removeLoaderIconIfAny(state: ChatState, action: RemoveLoaderIfAny): void {
    const { threadId } = action.payload;
    const thread = state.threads.byId[threadId];

    if (thread) {
      const targetIndex = thread.messages.findIndex(
        (message) => message.authorId === CHAT_AI_AUTHOR_KEY && message.id === LOADER_ICON
      );
      if (targetIndex !== -1) {
        thread.messages.splice(targetIndex, 1);
      }
    }
    state.isWaitingForResponse = false;
  },
  setDemoMode(state: ChatState, action: DemoModeAction): void {
    state.demo.isDemoMode = action.payload.isDemoMode;
    state.demo.version = action.payload.version;
  },
  setSpeechMode(state: ChatState, action: SpeechModeAction): void {
    state.speech.isSpeechMode = action.payload.isSpeechMode;
  },
  setSpeaking(state: ChatState, action: SpeakModeAction): void {
    state.speech.isSpeaking = action.payload.isSpeaking;
  },
};

export const slice = createSlice({
  name: 'chat',
  initialState,
  reducers,
});

export const { reducer } = slice;
